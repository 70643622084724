<template>
    <app-wrapper>
        <template v-slot:child>
            <CreateEntity :closeModal="() => toggleCreateEntity()" />
            <CreateProduct :closeModal="() => toggleCreateProduct()" :transactionCategory="'PRODUCT'" />
            <ProductVariantsModal
                :closeModal="() => toggleProductVariantsModal({ status: false, index: null })"
                @on-variant-selected="handleProdClickFromSearch"
            />
            <SelectAccount :overall-cost="state.totalWithAdditionalCost" :closeModal="() => displayAccountModal()" />
            <LowStockNotice
                :is-open="true"
                :lowStocks="state.lowItems"
                :processSubmit="processSubmit"
                :close-modal="closeLowStockModal"
            />
            <CreateAssetCategory :closeModal="() => toggleCreateAssetCategory()" />
            <CreateAsset :closeModal="() => toggleCreateAssetProduct()" :assetCategory="state.assetCategory" />
            <VOnboardingWrapper ref="wrapper" :steps="steps" />

            <div class="main-barx">
              <div class="w-70-l center mt4 mini-spacing box-border justify-responsivex">
                    <form ref="formRef" @submit.prevent="onSubmit" style="background: #fbfcfe">
                        <div class="flex flex-wrap justify-between pa3 box-border" style="font-size: 18px">
                            <!-- <div class="font-w2 pb3 pb0-l" style="color: #132c8c">Add a New Transaction</div> -->
                            <div class="font-w2 pb3 pb0-l" style="color: #132c8c">Transaction ID: {{ state.reference }}</div>
                        </div>

                        <!-- type of sale -->
                        <div class="flex flex-wrap items-center justify-between">
                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <label class="pb2">Date <span class="makeAstRed">*</span></label>
                                <div
                                    v-tippy="{
                                        content: 'Select the date of transaction',
                                        placement: 'top',
                                        interactive: true,
                                    }"
                                >
                                    <input
                                        class="product-input"
                                        type="date"
                                        name="date"
                                        placeholder="date"
                                        id="date"
                                        style="width: 100%"
                                        required
                                        v-model="state.date"
                                    />
                                </div>
                            </div>

                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <label class="pb2 entity-name"
                                    >Description
                                    <span
                                        v-if="
                                            state.type != 'sales' &&
                                            state.type != 'purchases' &&
                                            state.subtype != 'credit_repayment' &&
                                            state.subtype != 'bad_debt'
                                        "
                                        class="makeAstRed"
                                        >*</span
                                    ></label
                                >
                                <input
                                    class="product-input"
                                    type="text"
                                    name="description"
                                    placeholder="Enter description here"
                                    id="description"
                                    :required="
                                        state.type != 'sales' &&
                                        state.type != 'purchases' &&
                                        state.subtype != 'credit_repayment' &&
                                        state.subtype != 'bad_debt'
                                    "
                                    v-model="state.description"
                                />
                            </div>
                        </div>
                        <div class="flex flex-wrap items-center justify-between">
                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <label class="pb2 entity-name">Type <span class="makeAstRed">*</span></label>
                                <select
                                    id="select-type"
                                    class="product-input"
                                    v-model="state.transactionType"
                                    name="inflows"
                                    required
                                >
                                    <option value="inflows">Money In</option>
                                    <option value="outflows">Money Out</option>
                                </select>
                            </div>
                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <template v-if="state.transactionType === 'inflows'">
                                    <label class="pb2 entity-name">Category <span class="makeAstRed">*</span></label>
                                    <select
                                        id="select-category"
                                        class="product-input"
                                        v-model="state.subtype"
                                        name="inflows"
                                        required
                                        style="width: 100%"
                                    >
                                        <template v-for="inflow of inflows" v-bind:key="inflow">
                                            <option
                                                v-if="!inflow.permission || (role === 'owner' || role === 'admin' || rolePermissions?.includes(inflow?.permission || 0))"
                                                :value="inflow.value"
                                                :disabled="inflow.value !== 'sales' && plan?.businessPlan === 'Micro Business Plan'"
                                            >
                                                {{ inflow.name }}
                                            </option>
                                        </template>
                                    </select>
                                </template>

                                <template v-if="state.transactionType === 'outflows'">
                                    <label class="pb2 entity-name">Category <span class="makeAstRed">*</span></label>

                                    <select
                                        v-if="state.type === 'expenses' || state.type === 'purchases'"
                                        id="outflows"
                                        class="product-input"
                                        v-model="state.type"
                                        name="outflows"
                                        :required="state.type !== 'expenses'"
                                        style="width: 100%"
                                    >
                                        <template v-for="outflow of outflows" v-bind:key="outflow">
                                            <option
                                                v-if="!outflow.permission || (role === 'owner' || role === 'admin' || rolePermissions?.includes(outflow?.permission || 0))"
                                                :value="outflow.value"
                                                :disabled="!['expenses', 'purchases'].includes(outflow.value) && plan?.businessPlan === 'Micro Business Plan'"
                                            >
                                                {{ outflow.name }}
                                            </option>
                                        </template>
                                    </select>
                                    <select
                                        v-else
                                        id="outflows"
                                        class="product-input"
                                        v-model="state.subtype"
                                        name="outflows"
                                        :required="state.type !== 'expenses'"
                                        style="width: 100%"
                                    >
                                        <template v-for="outflow of outflows" v-bind:key="outflow">
                                            <option
                                                v-if="
                                                    !outflow.permission ||
                                                    role === 'owner' ||
                                                    role === 'admin' ||
                                                    rolePermissions?.includes(outflow?.permission || 0)
                                                "
                                                :value="outflow.value"
                                            >
                                                {{ outflow.name }}
                                            </option>
                                        </template>
                                    </select>
                                </template>
                                <template v-if="state.type === 'assets_purchase'">
                                    <p class="pb2">Assets acquisition</p>
                                    <div v-for="(asset, index) of state.assets" v-bind:key="index">
                                        <div class="pa3 flex flex-wrap items-center" style="gap: 0.5rem">
                                            <div
                                                class="flex flex-column products-card1"
                                                :style="`position: ${state.isRelative ? 'relative' : 'revert'}`"
                                            >
                                                <input
                                                    autofocus
                                                    autocomplete="off"
                                                    class="product-input"
                                                    :style="'border: 2px solid red !important;'"
                                                    type="text"
                                                    :name="asset.category"
                                                    :id="asset.category"
                                                    v-model="asset.category"
                                                    placeholder=" Land"
                                                    @change="(element) => handleAssetSearchOnChange(element.target.value, index)"
                                                    @input="(element) => handleAssetSearchOnChange(element.target.value, index)"
                                                    @focusout="
                                                        () => {
                                                            showDropDownOnSelectAsset(index, false)
                                                            handleIsRelative(false)
                                                        }
                                                    "
                                                    @focusin="
                                                        () => {
                                                            showDropDownOnSelectAsset(index, true)
                                                            handleIsRelative(true)
                                                        }
                                                    "
                                                    required
                                                />

                                                <template v-if="asset.productDropDown && asset.category">
                                                    <div
                                                        style="
                                                            position: absolute;
                                                            left: 0;
                                                            top: 100%;
                                                            height: auto;
                                                            width: 100%;
                                                            overflow: hidden;
                                                            text-overflow: ellipsis;
                                                            box-shadow: 0px 1px 4px 0px #2b59ff;
                                                            background: #132c8c;
                                                            z-index: 1;
                                                            color: #fff;
                                                        "
                                                        class="bg-white flex flex-column items-start"
                                                    >
                                                        <div
                                                            class="flex items-center"
                                                            style="
                                                                width: 100%;
                                                                gap: 0.5rem;
                                                                overflow: hidden;
                                                                text-overflow: ellipsis;
                                                            "
                                                        >
                                                            <button class="mr2" @click.prevent="toggleCreateAssetCategory(true)">
                                                                Create
                                                            </button>
                                                            <span
                                                                style="
                                                                    width: 100%;
                                                                    overflow: hidden;
                                                                    white-space: nowrap;
                                                                    text-overflow: ellipsis;
                                                                "
                                                            >
                                                                {{ asset.category }}</span
                                                            >
                                                        </div>

                                                        <div
                                                            class="mt2"
                                                            style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                            v-if="state.assetCategories?.length"
                                                        >
                                                            <ul class="w-100" v-if="state.assetCategories">
                                                                <li
                                                                    v-for="asset of state.assetCategories"
                                                                    v-bind:key="asset._id"
                                                                    class="pointer hover-bg-black-10 pa2 db"
                                                                    style="width: 100%"
                                                                    @click.prevent="handleAssetClickFromSearch(asset, index)"
                                                                >
                                                                    {{ asset.category }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <select v-model="state.subtype" id="assets_purchase" style="width: 100%">
                                        <option selected value="">-Choose Assets Category-</option>
                                        <template v-for="asset of state.assetCategories" v-bind:key="asset">
                                            <option :value="asset.category">{{ asset.category }}</option>
                                        </template>
                                    </select> -->
                                </template>
                            </div>
                        </div>

                        <div v-if="state.type === 'expenses'" class="flex flex-wrap items-center justify-between">
                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <label class="pb2 entity-name">Expenses <span class="makeAstRed">*</span></label>
                                <select id="expenses" class="product-input" v-model="state.subtype" style="width: 100%" required>
                                    <option selected value="">-Choose Expense Category-</option>
                                    <option v-for="expense of expenses" v-bind:key="expense" :value="expense.value">
                                        {{ expense.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <label class="pb2 entity-name">Expense Type <span class="makeAstRed">*</span></label>
                                <select class="product-input" v-model="state.expenseType" style="width: 100%" required>
                                    <option value="direct">Direct</option>
                                    <option v-if="plan?.businessPlan !== 'Micro Business Plan'" value="prepaid">Prepaid</option>
                                </select>
                                <!-- <small class="pl3 makeItalicExp">
                                    {{
                                        state.expenseType === 'direct' && state.type === 'expenses'
                                            ? 'The total amount of this expense will be recorded in your Profit & Loss/Income Statement immediately'
                                            : state.expenseType === 'prepaid'
                                            ? 'The total amount of this expense is spread over a period of months as specified below'
                                            : ''
                                    }}
                                </small> -->
                            </div>
                        </div>

                        <!-- amount/value & date -->
                        <div class="flex flex-wrap items-center justify-between">
                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <div id="total-amount" class="flex flex-column">
                                    <div class="flex pb2">
                                        <label>Amount</label>
                                        <div
                                            v-if="
                                                state.subtype === 'debt_repayment' ||
                                                state.subtype === 'bad_debt' ||
                                                state.subtype === 'credit_repayment'
                                            "
                                        >
                                            ( <span>Outstanding:</span>
                                            <span>{{
                                                formatAmount(state.entityOutstandingCopy, $store.state.Settings?.currency)
                                            }}</span
                                            >)
                                        </div>
                                        <div class="pl2" v-if="state.type === 'expenses' && state.expenseType === 'prepaid'">
                                            ( <span>Monthly Prepayment:</span>
                                            <span>{{
                                                formatAmount(state.monthlyRepayment, $store.state.Settings?.currency)
                                            }}</span
                                            >)
                                        </div>
                                        <div class="pl2" v-if="state.type === 'assets_purchase'">
                                            ( <span>Monthly Depreciation:</span>
                                            <span>{{
                                                formatAmount(state.monthlyDepreciation, $store.state.Settings?.currency)
                                            }}</span
                                            >)
                                        </div>
                                        <div class="pl2" v-if="state.subtype === 'assets_sales'">
                                            ( <span>Asset Value:</span>
                                            <span>{{ formatAmount(state.assetValue, $store.state.Settings?.currency) }}</span
                                            >)
                                        </div>
                                        <!--  -->
                                        <!--  Advance payment-->
                                        <div class="pl2" v-if="state.useAdvancePayment">
                                            ( <span>Advance Payment:</span>
                                            <span>{{
                                                formatAmount(state.entityPaidAdvanceAmount, $store.state.Settings?.currency)
                                            }}</span>
                                            )
                                        </div>
                                        <!--  Advance payment-->
                                    </div>
                                    <CurrencyInput
                                        v-if="
                                            state.subtype === 'debt_repayment' ||
                                            state.subtype === 'bad_debt' ||
                                            state.subtype === 'credit_repayment'
                                        "
                                        class="product-input makeWhiteTransaction"
                                        :max="state.entityOutstanding"
                                        name="totalAmountValue"
                                        :placeholder="state.totalWithAdditionalCost"
                                        id="totalAmountValue"
                                        v-model="state.amount"
                                        :options="numberFormat"
                                        required
                                        :disabled="
                                            (state.type === 'sales' && state.products.length > 0) ||
                                            (state.type === 'purchases' && state.products.length > 0) ||
                                            (state.subtype === 'damaged_stock' && state.products.length > 0) ||
                                            (state.subtype === 'missing_stock' && state.products.length > 0) ||
                                            (state.subtype === 'promotion_stock' && state.products.length > 0)
                                        "
                                        style="background: white"
                                    />
                                    <input
                                        v-else
                                        type="number"
                                        step=".01"
                                        :value="totalAmountValue === 0 ? state.amount : totalAmountValue"
                                        @change="(input) => (state.amount = parseFloat(input.target.value))"
                                        :options="numberFormat"
                                        class="product-input"
                                        name="totalAmountValue"
                                        :placeholder="state.totalWithAdditionalCost"
                                        id="totalAmountValue2"
                                        required
                                        :disabled="
                                            (state.type === 'sales' && state.products.length > 0) ||
                                            (state.type === 'purchases' && state.products.length > 0) ||
                                            (state.subtype === 'damaged_stock' && state.products.length > 0) ||
                                            (state.subtype === 'missing_stock' && state.products.length > 0) ||
                                            (state.subtype === 'promotion_stock' && state.products.length > 0) ||
                                            (state.type === 'assets_purchase' && state.products.length > 0)
                                        "
                                    />

                                    <template v-if="state.useAdvancePayment">
                                        <div class="flex pv2">
                                            <label>Amount Customer Pays</label>
                                            <!--  Advance payment-->
                                            <div class="pl2">
                                                (
                                                <!--  <span>Advance payment:</span>-->
                                                <span>{{
                                                    formatAmount(
                                                        state.entityAdvanceAmountPlaceholder,
                                                        $store.state.Settings?.currency
                                                    )
                                                }}</span>
                                                )
                                            </div>
                                            <!--  Advance payment-->
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="flex flex-column pa3 w-100 w-50-l">
                                <label class="pb2 entity-name">Account <span class="makeAstRed">*</span></label>
                                <select class="product-input" v-model="state.account" style="width: 100%" required>
                                    <option value="Cash">Cash</option>
                                    <option v-if="state.type === 'sales' || state.type === 'purchases'" value="credit">
                                        Credit
                                    </option>
                                    <option
                                        v-for="(account, index) in bankAccounts"
                                        v-bind:key="index"
                                        :value="account?.aliasName || account.bankName"
                                    >
                                        {{ account?.aliasName || account.bankName }}
                                    </option>
                                </select>
                            </div>

                            <div v-if="state.type === 'assets_purchase'" class="pt4 pr2 w-100">
                                <div class="flex flex-column">
                                    <div class="flex pb2">
                                        <label>Useful Life</label>
                                    </div>
                                    <input
                                        @change="(input) => (state.lifeSpan = parseFloat(input.target.value))"
                                        class="product-input"
                                        type="number"
                                        step="any"
                                        name="lifeSpan"
                                        :placeholder="state.lifeSpan"
                                        id="lifeSpan"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- upfront payment & date -->
                        <template
                            v-if="
                                role === 'owner' ||
                                role === 'admin' ||
                                rolePermissions?.includes(28) ||
                                rolePermissions?.includes(29)
                            "
                        >
                            <div
                                v-if="state.credit || state.subtype === 'borrowings'"
                                class="flex flex-wrap items-center justify-between"
                            >
                                <div class="flex flex-column pa3 w-100">
                                    <label class="pb2"
                                        >{{ state.credit ? 'Balance' : 'Refund' }} Due Date<span class="required">*</span></label
                                    >
                                    <div
                                        v-tippy="{
                                            content:
                                                'Select the date the customer has promised the pay the amount left as full payment for the product or service.',
                                            placement: 'top',
                                            interactive: true,
                                        }"
                                    >
                                        <input
                                            class="product-input"
                                            type="date"
                                            name="due_date"
                                            placeholder=""
                                            id="due_date"
                                            style="width: 100%"
                                            required
                                            v-model="state.due_date"
                                        />
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="pa3">
                            <div class="flex flex-column" :style="`position: ${state.isRelative ? 'relative' : 'revert'}`">
                                <label class="pb2 entity-name"
                                    >{{
                                        state.type === 'sales'
                                            ? 'Customer'
                                            : state.type === 'purchases'
                                            ? 'Supplier'
                                            : state.type === 'expenses'
                                            ? 'Vendor'
                                            : state.subtype === 'capital'
                                            ? 'Investor'
                                            : state.subtype === 'borrowings'
                                            ? 'Lender'
                                            : state.subtype === 'loans_given'
                                            ? 'Debtor'
                                            : state.subtype === 'assets_purchase'
                                            ? 'Supplier'
                                            : 'Entity'
                                    }}
                                    Name<span
                                        :class="state.credit && 'required'"
                                        class="makeAstRed"
                                        :style="`position: ${state.isRelative ? 'relative' : 'revert'}`"
                                        v-if="
                                            state.subtype === 'capital' ||
                                            state.subtype === 'loans_given' ||
                                            state.subtype === 'borrowings' ||
                                            state.subtype === 'debt_repayment' ||
                                            state.subtype === 'credit_repayment' ||
                                            state.subtype === 'advance_payment' ||
                                            state.subtype === 'bad_debt' ||
                                            state.credit === true
                                        "
                                    >
                                        *</span
                                    ></label
                                >
                                <div class="searchContainer2 justify-between items-center box-border w-100" style="padding: 0">
                                    <div
                                        style="width: 100%"
                                        v-tippy="
                                            state.type === 'sales'
                                                ? {
                                                      content: 'Input or select the name of the customer',
                                                      placement: 'top',
                                                      interactive: true,
                                                  }
                                                : state.type === 'purchases'
                                                ? {
                                                      content: 'Input or select the name of the supplier',
                                                      placement: 'top',
                                                      interactive: true,
                                                  }
                                                : state.subtype === 'loans_given'
                                                ? {
                                                      content: 'Input or select the name of the debtor',
                                                      placement: 'top',
                                                      interactive: true,
                                                  }
                                                : state.type === 'expenses'
                                                ? {
                                                      content: 'Input or select the name of the vendor',
                                                      placement: 'top',
                                                      interactive: true,
                                                  }
                                                : state.subtype === 'capital'
                                                ? {
                                                      content: 'Input or select the name of the investor',
                                                      placement: 'top',
                                                      interactive: true,
                                                  }
                                                : state.subtype === 'borrowings'
                                                ? {
                                                      content: 'Input or select the name of the lender',
                                                      placement: 'top',
                                                      interactive: true,
                                                  }
                                                : {
                                                      content: 'Input or select the name of the customer',
                                                      placement: 'top',
                                                      interactive: true,
                                                  }
                                        "
                                    >
                                        <input
                                            id="add-customer"
                                            autocomplete="off"
                                            class="product-input searchBoxx pl2x w-100"
                                            type="text"
                                            name="entity"
                                            :placeholder="
                                                state.type === 'sales'
                                                    ? 'Please enter the name and select from dropdown or click CREATE to create a new Customer'
                                                    : state.type === 'purchases'
                                                    ? 'Please enter the name and select from dropdown or click CREATE to create a new Supplier'
                                                    : state.type === 'expenses'
                                                    ? 'Please enter the name and select from dropdown or click CREATE to create a new Vendor'
                                                    : state.subtype === 'capital'
                                                    ? 'Please enter the name and select from dropdown or click CREATE to create a new Investor'
                                                    : state.subtype === 'borrowings'
                                                    ? 'Please enter the name and select from dropdown or click CREATE to create a new Lender'
                                                    : state.subtype === 'loans_given'
                                                    ? 'Please enter the name and select from dropdown or click CREATE to create a new Debtor'
                                                    : state.subtype === 'assets_purchase'
                                                    ? 'Please enter the name and select from dropdown or click CREATE to create a new Supplier'
                                                    : 'Please enter the name and select from dropdown or click CREATE to create a new Entity'
                                            "
                                            @input="(element) => handleEntitySearchOnChange(element.target.value)"
                                            @focusout="
                                                () => {
                                                    showDropDownOnSelectEntity(false)
                                                    handleIsRelative(false)
                                                }
                                            "
                                            @focusin="
                                                () => {
                                                    showDropDownOnSelectEntity(true)
                                                    handleIsRelative(true)
                                                }
                                            "
                                            v-model="state.entity"
                                            :required="
                                                state.subtype === 'loans_given' ||
                                                state.subtype === 'capital' ||
                                                state.subtype === 'borrowings' ||
                                                state.subtype === 'debt_repayment' ||
                                                state.subtype === 'credit_repayment' ||
                                                state.subtype === 'advance_payment' ||
                                                state.subtype === 'withdrawal' ||
                                                state.subtype === 'bad_debt' ||
                                                (state.type === 'sales' && state.credit) ||
                                                (state.type === 'purchases' && state.credit)
                                            "
                                        />
                                    </div>
                                    <template v-if="state.entityDropDown && state.entity?.length">
                                        <div
                                            style="
                                                position: absolute;
                                                left: 0;
                                                top: 100%;
                                                height: auto;
                                                width: 100%;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                box-shadow: 0px 1px 4px 0px #2b59ff;
                                                z-index: 1000;
                                            "
                                            class="bg-white flex flex-column items-start"
                                        >
                                            <div
                                                class="mt2"
                                                style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                v-if="EntitySearchedList.length"
                                            >
                                                <ul class="w-100" style="margin: 0; padding: 10px 0">
                                                    <li
                                                        v-for="ent of EntitySearchedList"
                                                        v-bind:key="ent._id"
                                                        class="pointer hover-bg-black-10 pa2 db"
                                                        style="width: 100%; margin: 0"
                                                        @click.prevent="handleEntityClickFromSearch(ent)"
                                                    >
                                                        <span v-if="ent?.lastName !== undefined">
                                                            {{ `${ent?.firstName} ${ent?.lastName}` || ent?.company }}
                                                        </span>
                                                        <span v-else>
                                                            {{ `${ent?.firstName}` || ent?.company }}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div
                                                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(30)"
                                                class="flex items-center"
                                                style="width: 100%; gap: 0.5rem; overflow: hidden; text-overflow: ellipsis"
                                            >
                                                <button
                                                    class="mr2x pa2"
                                                    style="
                                                        width: 100%;
                                                        overflow: hidden;
                                                        white-space: nowrap;
                                                        text-overflow: ellipsis;
                                                    "
                                                    @click.prevent="toggleCreateEntity(true)"
                                                >
                                                    <font-awesome-icon icon="fa fa-plus-circle" /> Create New Customer
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="">
                            <template v-if="state.subtype === 'assets_sales'">
                                <p class="pb2">Assets Management</p>

                                <select v-model="state.assetCategory" id="assets_sales" style="width: 100%">
                                    <option selected value="">-Choose Assets Category-</option>
                                    <option v-for="asset of state.assetCategories" v-bind:key="asset" :value="asset._id">
                                        {{ asset.category }}
                                    </option>
                                </select>
                            </template>
                        </div>
                        <div v-if="state.type === 'sales'" class="pl3" v-show="false">
                            <div class="" style="margin-top: 16px">Please select your business type</div>

                            <div class="pt2 flex flex-row mr3">
                                <input class="mr1" type="radio" id="product" value="PRODUCT" v-model="state.orgCategory" />
                                <label class="pr2" for="product">Product</label>

                                <input class="mr1" type="radio" id="service" value="SERVICE" v-model="state.orgCategory" />
                                <label class="pr2" for="service">Service</label>
                            </div>
                        </div>

                        <template
                            v-if="
                                (state.type === 'inflows' && state.subtype === 'purchase_return') ||
                                (state.type === 'outflows' && state.type === 'sales_return')
                            "
                        >
                            <form class="pa3 flex flex-wrap" @submit.prevent="handleGenerateProductReturns">
                                <input
                                    type="text"
                                    class="product-input mr2"
                                    name="tx-reference"
                                    maxlength="6"
                                    v-model="state.reference"
                                />
                                <button>Generate Products</button>
                            </form>
                        </template>

                        <template
                            v-if="
                                state.subtype === 'damaged_stock' ||
                                state.subtype === 'missing_stock' ||
                                state.subtype === 'promotion_stock'
                            "
                        >
                            <div v-for="(product, index) of state.products" v-bind:key="index">
                                <div class="pa3 flex flex-wrap items-center" style="gap: 0.5rem">
                                    <div
                                        class="flex flex-column products-card1"
                                        :style="`position: ${state.isRelative ? 'relative' : 'revert'} !important;`"
                                    >
                                        <label class="pb2"
                                            >{{
                                                state.orgCategory !== 'SERVICE'
                                                    ? state.type === 'sales'
                                                        ? 'Item(s)'
                                                        : 'Item(s)'
                                                    : 'Service'
                                            }}<span class="required">*</span></label
                                        >
                                        <input
                                            autofocus
                                            autocomplete="off"
                                            class="product-input"
                                            :style="
                                                !productIds[index] &&
                                                'border: 2px solid red !important; background: #eee !important;'
                                            "
                                            type="text"
                                            :name="product.name"
                                            :id="product.name"
                                            v-model="product.name"
                                            placeholder="Input Product/Service name"
                                            @input="(element) => handleProductSearchOnChange(element.target.value, index)"
                                            @keypress.prevent.enter="handleSearchOrCreateProduct(1)"
                                            @focusout="
                                                () => {
                                                    showDropDownOnSelectProduct(index, false)
                                                    handleIsRelative(false)
                                                }
                                            "
                                            @focusin="
                                                () => {
                                                    showDropDownOnSelectProduct(index, true)
                                                    handleIsRelative(true)
                                                }
                                            "
                                            required
                                            :max="parseFloat(product.instock)"
                                        />

                                        <template v-if="product.productDropDown && product.name?.length">
                                            <div
                                                style="
                                                    position: absolute;
                                                    left: 0;
                                                    top: 100%;
                                                    height: auto;
                                                    width: 100%;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    box-shadow: 0px 1px 4px 0px #2b59ff;
                                                    background: #132c8c;
                                                    z-index: 1;
                                                    color: #fff;
                                                "
                                                class="bg-white flex flex-column items-start"
                                            >
                                                <div
                                                    class="mt2"
                                                    style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                    v-if="productEntrySearchList?.length"
                                                >
                                                    <ul class="w-100" v-if="productEntrySearchList">
                                                        <li
                                                            v-for="(prod, i) of productEntrySearchList"
                                                            v-bind:key="prod._id"
                                                            :ref="
                                                                (el) => {
                                                                    retrievedProducts[i] = el
                                                                }
                                                            "
                                                            class="pointer hover-bg-black-10 pa2 db"
                                                            style="width: 100%"
                                                            @click.prevent="handleProdClickFromSearch(prod, index)"
                                                        >
                                                            <div>{{ prod.name }}</div>
                                                            <div v-if="prod?.hasVariants" class="text-muted">
                                                                {{ prod.variantsCount }} variants
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </template>
                                    </div>

                                    <div class="flex flex-column products-card">
                                        <label class="pb2">Sku*</label>
                                        <input
                                            class="product-input"
                                            :class="product.instock <= 1 ? 'red' : ''"
                                            :style="
                                                product.instock <= 1
                                                    ? 'border: 1px solid red !important; background: #eee !important; outline: 1px solid red !important; background: #eee !important;'
                                                    : ''
                                            "
                                            type="text"
                                            step="any"
                                            :name="product.sku"
                                            v-model="product.sku"
                                            placeholder=""
                                            :id="product.sku"
                                            :disabled="true"
                                        />
                                    </div>

                                    <div class="flex flex-column products-card">
                                        <label class="pb2"
                                            >Quantity*<span
                                                v-if="state.orgCategory === 'PRODUCT'"
                                                class="required"
                                                :class="product.instock <= 10 ? 'red' : 'black'"
                                                >: ({{ formatQuantity(product.instock || 0) }} In-stock)</span
                                            ></label
                                        >
                                        <CurrencyInput
                                            class="product-input"
                                            :class="state.type === 'sales' && product.quantity > product.instock ? 'red' : ''"
                                            :style="
                                                (state.type === 'sales' && product.instock <= 10) ||
                                                (state.type === 'sales' && product.quantity < product.instock) // eslint-disable-next-line max-len
                                                    ? 'border: 1px solid red !important; background: #eee !important; outline: 1px solid red !important; background: #eee !important;'
                                                    : ''
                                            "
                                            :name="product.quantity"
                                            v-model="product.quantity"
                                            :options="numberFormat"
                                            placeholder="0"
                                            :id="product.quantity"
                                            :disabled="!productIds[index]"
                                            required
                                            @change="handleLowProductAlert(index)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="tr b pa3">
                                <div class="pb3 box-border-bottom">
                                    <a href="" style="color: #132c8c" @click.prevent="addMoreProducts">
                                        + Add more {{ state.orgCategory === 'PRODUCT' ? 'products' : 'services' }}
                                    </a>
                                </div>
                            </div>
                        </template>

                        <template v-if="state.type === 'assets_purchase' || state.subtype === 'assets_sales'">
                            <div v-for="(product, index) of state.products" v-bind:key="index">
                                <div class="pa3 flex flex-wrap items-center" style="gap: 0.5rem">
                                    <div
                                        class="flex flex-column products-card1"
                                        :style="`position: ${state.isRelative ? 'relative' : 'revert'}`"
                                    >
                                        <label class="pb2">Asset Name<span class="required">*</span></label>
                                        <input
                                            autofocus
                                            autocomplete="off"
                                            class="product-input"
                                            :style="
                                                !productIds[index] &&
                                                'border: 2px solid red !important; background: #eee !important;'
                                            "
                                            type="text"
                                            :name="product.name"
                                            :id="product.name"
                                            v-model="product.name"
                                            placeholder="Input Product/Service name"
                                            @input="
                                                (element) => handleProductSearchOnChange(element.target.value, index, 'asset')
                                            "
                                            @keypress.prevent.enter="handleSearchOrCreateProduct(2)"
                                            @focusout="
                                                () => {
                                                    showDropDownOnSelectProduct(index, false)
                                                    handleIsRelative(false)
                                                }
                                            "
                                            @focusin="
                                                () => {
                                                    showDropDownOnSelectProduct(index, true)
                                                    handleIsRelative(true)
                                                }
                                            "
                                            required
                                            :max="parseFloat(product.instock)"
                                        />

                                        <template v-if="product.productDropDown && product.name?.length">
                                            <div
                                                style="
                                                    position: absolute;
                                                    left: 0;
                                                    top: 100%;
                                                    height: auto;
                                                    width: 100%;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    box-shadow: 0px 1px 4px 0px #2b59ff;
                                                    background: #132c8c;
                                                    z-index: 1;
                                                    color: #fff;
                                                "
                                                class="bg-white flex flex-column items-start"
                                            >
                                                <div
                                                    class="flex items-center"
                                                    style="width: 100%; gap: 0.5rem; overflow: hidden; text-overflow: ellipsis"
                                                >
                                                    <button
                                                        class="mr2"
                                                        @click.prevent="toggleCreateAssetProduct(true, index, product.name)"
                                                    >
                                                        Create
                                                    </button>
                                                    <span
                                                        style="
                                                            width: 100%;
                                                            overflow: hidden;
                                                            white-space: nowrap;
                                                            text-overflow: ellipsis;
                                                        "
                                                    >
                                                        {{ product.name }}</span
                                                    >
                                                </div>

                                                <div
                                                    class="mt2"
                                                    style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                    v-if="productEntrySearchList?.length"
                                                >
                                                    <ul class="w-100" v-if="productEntrySearchList">
                                                        <li
                                                            v-for="(prod, i) of productEntrySearchList"
                                                            v-bind:key="prod._id"
                                                            :ref="
                                                                (el) => {
                                                                    retrievedProducts2[i] = el
                                                                }
                                                            "
                                                            class="pointer hover-bg-black-10 pa2 db"
                                                            style="width: 100%"
                                                            @click.prevent="handleProdClickFromSearch(prod, index)"
                                                        >
                                                            <div>{{ prod.name }}</div>
                                                            <div v-if="prod?.hasVariants" class="text-muted">
                                                                {{ prod.variantsCount }} variants
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </template>
                                    </div>

                                    <template v-if="state.type === 'assets_sale' || state.type === 'assets_purchase'">
                                        <div class="flex flex-column products-card">
                                            <label v-if="state.orgCategory !== 'SERVICE'" class="pb2">
                                                {{ state.type === 'sales' ? 'Selling' : 'Buying' }} Price<span class="required"
                                                    >*</span
                                                >
                                            </label>
                                            <label v-if="state.orgCategory === 'SERVICE'" class="pb2">
                                                Unit Price<span class="required">*</span>
                                            </label>
                                            <CurrencyInput
                                                v-if="role === 'owner' || role === 'admin'"
                                                class="product-input"
                                                :name="product.price"
                                                v-model="product.price"
                                                :options="numberFormat"
                                                placeholder="0.00"
                                                :id="product.price"
                                                @change="onChangeProductIds(index)"
                                                :disabled="!productIds[index]"
                                                required
                                            />

                                            <CurrencyInput
                                                v-else
                                                class="product-input"
                                                :name="product.price"
                                                v-model="product.price"
                                                :options="numberFormat"
                                                placeholder="0.00"
                                                :id="product.price"
                                                @change="onChangeProductIds(index)"
                                                disabled
                                                required
                                            />
                                        </div>
                                    </template>

                                    <div class="flex flex-column products-card">
                                        <label class="pb2"
                                            >Quantity*<span
                                                v-if="state.orgCategory === 'PRODUCT'"
                                                class=""
                                                :class="product.instock <= 10 ? 'red' : 'black'"
                                                >: ({{ product.instock || 0 }} In-stock)</span
                                            ></label
                                        >

                                        <CurrencyInput
                                            class="product-input"
                                            :class="state.type === 'sales' && product.quantity > product.instock ? 'red' : ''"
                                            :style="
                                                (state.type === 'sales' && product.instock <= 10) ||
                                                (state.type === 'sales' && product.quantity < product.instock) // eslint-disable-next-line max-len
                                                    ? 'border: 1px solid red !important; background: #eee !important; outline: 1px solid red !important; background: #eee !important;'
                                                    : ''
                                            "
                                            :name="product.quantity"
                                            v-model="product.quantity"
                                            :options="numberFormat"
                                            placeholder="0"
                                            :id="product.quantity"
                                            :disabled="!productIds[index]"
                                            required
                                            @change="handleLowProductAlert(index)"
                                        />
                                    </div>

                                    <div class="pt4 poniter" @click.prevent="deleteProduct(index)">
                                        <img :src="require('@/assets/images/delete.svg')" alt="Delete" />
                                    </div>

                                    <p v-if="state.orgCategory === 'PRODUCT'" class="pt1">
                                        {{ product.price * product.quantity }}
                                    </p>
                                </div>
                            </div>

                            <div class="tr b pa3">
                                <div class="pb3 box-border-bottom">
                                    <a href="" style="color: #132c8c" @click.prevent="addMoreProducts"> + Add more Asset </a>
                                </div>
                            </div>
                        </template>

                        <template
                            v-if="
                                state.type === 'sales' ||
                                state.type === 'purchases' ||
                                state.subtype === 'sales_return' ||
                                state.subtype === 'purchase_return' ||
                                state.subtype === 'stock_introduced'
                            "
                        >
                            <p v-if="state.products?.length" class="pa3 b">
                                {{ state.orgCategory !== 'SERVICE' ? 'Products' : 'Service' }}
                            </p>
                            <div id="enter-item" v-for="(product, index) of state.products" v-bind:key="index">
                                <div class="pa3 flex flex-wrap items-center" style="gap: 0.5rem">
                                    <div
                                        class="flex flex-column products-card1"
                                        :style="`position: ${state.isRelative ? 'relative' : 'revert'}`"
                                    >
                                        <label class="pb2"
                                            >{{
                                                state.orgCategory !== 'SERVICE'
                                                    ? state.type === 'sales'
                                                        ? 'Item(s)'
                                                        : 'Item(s)'
                                                    : 'Service'
                                            }}
                                            <span class="required">*</span>
                                        </label>
                                        <div
                                            v-tippy="{
                                                content: 'Input/select the Product or Service',
                                                placement: 'top',
                                                interactive: true,
                                            }"
                                            style="width: 100%"
                                        >
                                            <input
                                                autofocus
                                                autocomplete="off"
                                                class="product-input"
                                                :style="
                                                    !productIds[index] &&
                                                    'border: 2px solid red !important; background: #eee !important; width: 100%;'
                                                "
                                                type="text"
                                                style="width: 100%"
                                                :name="product.name"
                                                :id="product.name"
                                                v-model="product.name"
                                                placeholder="Input Product/Service name"
                                                @input="(element) => handleProductSearchOnChange(element.target.value, index)"
                                                @keypress.prevent.enter="handleSearchOrCreateProduct(3)"
                                                @focusout="
                                                    () => {
                                                        showDropDownOnSelectProduct(index, false)
                                                        handleIsRelative(false)
                                                    }
                                                "
                                                @focusin="
                                                    () => {
                                                        showDropDownOnSelectProduct(index, true)
                                                        handleIsRelative(true)
                                                    }
                                                "
                                                required
                                                :max="parseFloat(product.instock)"
                                            />
                                        </div>

                                        <template v-if="product.productDropDown && product.name?.length">
                                            <div
                                                style="
                                                    position: absolute;
                                                    left: 0;
                                                    top: 100%;
                                                    height: auto;
                                                    width: 100%;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    box-shadow: 0px 1px 4px 0px #2b59ff;
                                                    background: #132c8c;
                                                    z-index: 1;
                                                    color: #fff;
                                                "
                                                class="bg-white flex flex-column items-start"
                                            >
                                                <div
                                                    v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(32)"
                                                    class="flex items-center"
                                                    style="width: 100%; gap: 0.5rem; overflow: hidden; text-overflow: ellipsis"
                                                >
                                                    <button
                                                        class="mr2"
                                                        @click.prevent="
                                                            toggleCreateProduct(true, index, product.name, state.orgCategory)
                                                        "
                                                        :ref="
                                                            (el) => {
                                                                createProductBtn = el
                                                            }
                                                        "
                                                    >
                                                        Create New Product
                                                    </button>
                                                </div>

                                                <div
                                                    class="mt2"
                                                    style="width: 100%; max-height: 250px; overflow-y: scroll"
                                                    v-if="productEntrySearchList?.length"
                                                >
                                                    <ul class="w-100" v-if="productEntrySearchList">
                                                        <li
                                                            v-for="(prod, i) of productEntrySearchList"
                                                            v-bind:key="prod._id"
                                                            :ref="
                                                                (el) => {
                                                                    retrievedProducts3[i] = el
                                                                }
                                                            "
                                                            class="pointer hover-bg-black-10 pa2 db"
                                                            style="width: 100%"
                                                            @click.prevent="handleProdClickFromSearch(prod, index)"
                                                        >
                                                            <div>{{ prod.name }}</div>
                                                            <div v-if="prod?.hasVariants" class="text-muted">
                                                                {{ prod.variantsCount }} variants
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </template>
                                    </div>

                                    <template v-if="state.type === 'sales' || state.type === 'purchases'">
                                        <div class="flex flex-column products-card">
                                            <label v-if="state.orgCategory !== 'SERVICE'" class="pb2"
                                                >{{ state.type === 'sales' ? 'Selling' : 'Buying' }} Price<span class="required"
                                                    >*</span
                                                ></label
                                            >
                                            <label v-if="state.orgCategory === 'SERVICE'" class="pb2"
                                                >Unit Price<span class="required">*</span></label
                                            >
                                            <div
                                                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(37)"
                                                v-tippy="{
                                                    content: 'Input the amount you charged for the product or service',
                                                    placement: 'top',
                                                    interactive: true,
                                                }"
                                            >
                                                <CurrencyInput
                                                    class="product-input"
                                                    :name="product.price"
                                                    v-model="product.price"
                                                    :options="numberFormat"
                                                    placeholder="0.00"
                                                    :id="product.price"
                                                    @change="onChangeProductIds(index)"
                                                    :disabled="
                                                        state.type !== 'sales' && state.type !== 'purchases' && !productIds[index]
                                                    "
                                                    required
                                                    style="width: 100%"
                                                />
                                            </div>

                                            <CurrencyInput
                                                v-else
                                                class="product-input"
                                                :name="product.price"
                                                v-model="product.price"
                                                :options="numberFormat"
                                                placeholder="0.00"
                                                :id="product.price"
                                                @change="onChangeProductIds(index)"
                                                disabled
                                                required
                                            />
                                        </div>
                                    </template>

                                    <div class="flex flex-column products-card">
                                        <label class="pb2"
                                            >Quantity*<span
                                                v-if="state.orgCategory === 'PRODUCT'"
                                                class=""
                                                :class="
                                                    product.instock < 10 || product.quantity >= product.instock ? 'red' : 'black'
                                                "
                                                >: ({{ product.instock || 0 }} In-stock)</span
                                            ></label
                                        >

                                        <div
                                            v-tippy="{
                                                content:
                                                    'Input the number of products or the number of time you performed that service',
                                                placement: 'top',
                                                interactive: true,
                                            }"
                                            class="addMeasurement"
                                            style="width: 100%"
                                        >
                                            <CurrencyInput
                                                class="product-input"
                                                :class="
                                                    (state.type === 'sales' && product.quantity > product.instock) ||
                                                    product.quantity >= product.instock
                                                        ? 'red'
                                                        : ''
                                                "
                                                :style="
                                                    (state.type === 'sales' && product.instock <= 10) ||
                                                    (state.type === 'sales' && product.quantity > product.instock) // eslint-disable-next-line max-len
                                                        ? 'border: 1px solid red ; background: #eee !important; outline: 1px solid red; background: #eee; width: 100%;'
                                                        : ''
                                                "
                                                style="width: 100%"
                                                :name="product.quantity"
                                                v-model="product.quantity"
                                                :options="numberFormat"
                                                placeholder="0"
                                                :id="product.quantity"
                                                :disabled="
                                                    state.type !== 'sales' && state.type !== 'purchases' && !productIds[index]
                                                "
                                                required
                                                @change="handleLowProductAlert(index)"
                                            /><span class="measureUnit">{{ product.measurement }}</span>
                                        </div>
                                    </div>
                                    <div class="pt4" @click.prevent="deleteProduct(index)">
                                        <img :src="require('@/assets/images/delete.svg')" alt="Delete" />
                                    </div>

                                    <div style="width: 98%; display: flex; justify-content: flex-end; font-weight: bold">
                                        <p v-if="state.orgCategory === 'PRODUCT'" class="pt1">
                                            Total Amount: {{ formatAmount(product.price * product.quantity, $store.state.Settings?.currency) }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div v-if="plan?.businessPlan !== 'Micro Business Plan'" class="tr b pa3">
                                <div class="pb3 box-border-bottom">
                                    <a id="more-items" href="" style="color: #132c8c" @click.prevent="addMoreProducts">
                                        + Add
                                        {{
                                            state.orgCategory === 'PRODUCT'
                                                ? state.type === 'sales'
                                                    ? 'item(s)'
                                                    : 'products'
                                                : 'services'
                                        }}
                                    </a>
                                </div>
                            </div>
                        </template>

                        <!-- products -->

                        <div class="tr pa3">
                            <!-- add shipping-->
                            <div
                                class="flex justify-between pb3"
                                v-if="state.extras.shipping && (state.type === 'sales' || state.type === 'purchases')"
                            >
                                <div></div>
                                <div class="w-50-l w-100">
                                    <div class="flex items-center justify-between">
                                        <p>Delivery</p>

                                        <div class="box-border-bottom tr b">
                                            <router-link
                                                to="#"
                                                @click.prevent="toggleExtras(false, 'shipping')"
                                                style="color: #132c8c"
                                            >
                                                - Remove Delivery
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="flex w-100 mt2">
                                        <div>
                                            <select
                                                style="
                                                    background-color: white;
                                                    color: #634f4f;
                                                    border: 1px solid rgb(118, 118, 118);
                                                    border-radius: 0;
                                                "
                                                name="shippingType"
                                                id="shippingType"
                                                v-model="state.shippingType"
                                                class="product-input"
                                            >
                                                <option value="fixed">Fixed</option>
                                                <option value="percent">Percentage %</option>
                                            </select>
                                        </div>
                                        <div
                                            style="width: 100%"
                                            v-tippy="{
                                                content:
                                                    'This is the amount you will charge the customer to deliver the product to them directly. Add this, if you are sending a delivery or dispatch',
                                                placement: 'top',
                                                interactive: true,
                                            }"
                                        >
                                            <CurrencyInput
                                                name="shipping"
                                                id="shipping"
                                                v-model="state.shipping"
                                                :options="numberFormat"
                                                class="w-100 pl2 product-input"
                                                style="outline: none"
                                                placeholder="Enter amount or Percentage"
                                                aria-label=""
                                                aria-describedby=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- commission  -->
                            <div
                                class="flex justify-between pb3"
                                v-if="state.extras.commission && (state.type === 'sales' || state.type === 'purchases')"
                            >
                                <div></div>
                                <div class="w-50-l w-100">
                                    <div class="flex items-center justify-between">
                                        <p>Commission</p>

                                        <div class="box-border-bottom tr b">
                                            <router-link
                                                to="#"
                                                @click.prevent="toggleExtras(false, 'commission')"
                                                style="color: #132c8c"
                                            >
                                                - Remove Commission
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="flex w-100 mt2">
                                        <div>
                                            <select
                                                style="
                                                    background-color: white;
                                                    color: #634f4f;
                                                    border: 1px solid rgb(118, 118, 118);
                                                    border-radius: 0;
                                                "
                                                name="commissionType"
                                                id="commissionType"
                                                v-model="state.commissionType"
                                                class="product-input"
                                            >
                                                <option value="fixed">Fixed</option>
                                                <option value="percent">Percentage %</option>
                                            </select>
                                        </div>
                                        <CurrencyInput
                                            id="commission"
                                            name="commission"
                                            v-model="state.commission"
                                            :options="numberFormat"
                                            class="w-100 pl2 product-input"
                                            style="outline: none"
                                            placeholder=""
                                            aria-label=""
                                            aria-describedby=""
                                        />
                                    </div>
                                </div>
                            </div>

                            <!-- VAT  -->
                            <div
                                class="flex justify-between pb3"
                                v-if="state.extras.vat && (state.type === 'sales' || state.type === 'purchases')"
                            >
                                <div></div>
                                <div class="w-50-l w-100">
                                    <div class="flex items-center justify-between">
                                        <p>VAT</p>

                                        <div class="box-border-bottom tr b">
                                            <router-link
                                                to="#"
                                                @click.prevent="toggleExtras(false, 'vat')"
                                                style="color: #132c8c"
                                            >
                                                - Remove VAT
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="flex w-100 mt2">
                                        <div>
                                            <select
                                                style="
                                                    background-color: white;
                                                    color: #634f4f;
                                                    border: 1px solid rgb(118, 118, 118);
                                                    border-radius: 0;
                                                "
                                                name="vatType"
                                                id="vatType"
                                                v-model="state.vatType"
                                                class="product-input"
                                            >
                                                <option value="fixed">Fixed</option>
                                                <option value="percent">Percentage %</option>
                                            </select>
                                        </div>
                                        <div
                                            style="width: 100%"
                                            v-tippy="{
                                                content:
                                                    'This is Percentage for value added tax as stipiluated by the government e.g 7.5%',
                                                placement: 'top',
                                                interactive: true,
                                            }"
                                        >
                                            <CurrencyInput
                                                id="vat"
                                                name="vat"
                                                v-model="state.vat"
                                                :options="numberFormat"
                                                class="w-100 pl2 product-input"
                                                style="outline: none"
                                                placeholder=""
                                                aria-label=""
                                                aria-describedby=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Discount  -->
                            <div
                                class="flex justify-between pb3"
                                v-if="state.extras.discount && (state.type === 'sales' || state.type === 'purchases')"
                            >
                                <div></div>
                                <div class="w-50-l w-100">
                                    <div class="flex items-center justify-between">
                                        <p>Discount</p>

                                        <div class="box-border-bottom tr b">
                                            <router-link
                                                to="#"
                                                @click.prevent="toggleExtras(false, 'discount')"
                                                style="color: #132c8c"
                                            >
                                                - Remove Discount
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="flex w-100 mt2">
                                        <div>
                                            <select
                                                style="
                                                    background-color: white;
                                                    color: #634f4f;
                                                    border: 1px solid rgb(118, 118, 118);
                                                    border-radius: 0;
                                                "
                                                name="shippingType"
                                                id="discountType"
                                                v-model="state.discountType"
                                                class="product-input"
                                            >
                                                <option value="fixed">Fixed</option>
                                                <option value="percent">Percentage %</option>
                                            </select>
                                        </div>
                                        <CurrencyInput
                                            id="discount"
                                            name="discount"
                                            v-model="state.discount"
                                            :options="numberFormat"
                                            class="w-100 pl2 product-input"
                                            style="outline: none"
                                            placeholder=""
                                            aria-label=""
                                            aria-describedby=""
                                        />
                                    </div>
                                </div>
                            </div>

                            <!-- Other Fees  -->
                            <div
                                class="flex justify-between pb3"
                                v-if="state.extras.others && (state.type === 'sales' || state.type === 'purchases')"
                            >
                                <div></div>
                                <div class="w-50-l w-100">
                                    <div class="flex items-center justify-between">
                                        <p>Others</p>

                                        <div class="box-border-bottom tr b">
                                            <router-link
                                                to="#"
                                                @click.prevent="toggleExtras(false, 'others')"
                                                style="color: #132c8c"
                                            >
                                                - Remove Others
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="flex w-100 mt2">
                                        <div>
                                            <select
                                                style="
                                                    background-color: white;
                                                    color: #634f4f;
                                                    border: 1px solid rgb(118, 118, 118);
                                                    border-radius: 0;
                                                "
                                                name="othersType"
                                                id="othersType"
                                                v-model="state.othersType"
                                                class="product-input"
                                            >
                                                <option value="fixed">Fixed</option>
                                                <option value="percent">Percentage %</option>
                                            </select>
                                        </div>
                                        <div
                                            style="width: 100%"
                                            v-tippy="{
                                                content:
                                                    ' Input any other charges that you might have incurred and agreed go pass to the customer',
                                                placement: 'top',
                                                interactive: true,
                                            }"
                                        >
                                            <CurrencyInput
                                                id="others"
                                                name="others"
                                                v-model="state.others"
                                                :options="numberFormat"
                                                class="w-100 pl2 product-input"
                                                style="outline: none"
                                                placeholder=""
                                                aria-label=""
                                                aria-describedby=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="state.extras.tags" class="flex flex-column pb3 w-100">
                                <div class="flex justify-between">
                                    <label>Tags</label>
                                    <div class="box-border-bottom tr b">
                                        <router-link to="#" @click.prevent="toggleExtras(false, 'tags')" style="color: #132c8c">
                                            - Remove Tags
                                        </router-link>
                                    </div>
                                </div>
                                <vue-tags-input
                                    class="mt2 w-100"
                                    v-model="state.tag"
                                    :tags="state.tags"
                                    :validation="state.validation"
                                    :autocomplete-items="state.myTags"
                                    :max-tags="5"
                                    placeholder="Add or select tag"
                                    @tags-changed="(newTags) => (state.tags = newTags)"
                                />
                            </div>

                            <div id="additional-cost" class="flex flex-wrap items-center justify-end pa3" style="gap: 1.5rem">
                                <div
                                    class="b tr"
                                    v-if="!state.extras.shipping && (state.type === 'sales' || state.type === 'purchases')"
                                >
                                    <router-link to="#" @click.prevent="toggleExtras(true, 'shipping')" style="color: #132c8c">
                                        + Add Delivery
                                    </router-link>
                                </div>
                                <div
                                    class="b tr"
                                    v-if="!state.extras.commission && (state.type === 'sales' || state.type === 'purchases')"
                                >
                                    <router-link to="#" @click.prevent="toggleExtras(true, 'commission')" style="color: #132c8c">
                                        + Add commission
                                    </router-link>
                                </div>
                                <div
                                    class="b tr"
                                    v-if="!state.extras.vat && (state.type === 'sales' || state.type === 'purchases')"
                                >
                                    <router-link to="#" @click.prevent="toggleExtras(true, 'vat')" style="color: #132c8c">
                                        + Add VAT
                                    </router-link>
                                </div>
                                <div
                                    class="b tr"
                                    v-if="!state.extras.discount && (state.type === 'sales' || state.type === 'purchases')"
                                >
                                    <router-link to="#" @click.prevent="toggleExtras(true, 'discount')" style="color: #132c8c">
                                        + Add discount
                                    </router-link>
                                </div>
                                <div
                                    class="b tr"
                                    v-if="!state.extras.others && (state.type === 'sales' || state.type === 'purchases')"
                                >
                                    <router-link to="#" @click.prevent="toggleExtras(true, 'others')" style="color: #132c8c">
                                        + Add other fees
                                    </router-link>
                                </div>
                                <div class="b tr" v-if="!state.extras.tags">
                                    <router-link to="#" @click.prevent="toggleExtras(true, 'tags')" style="color: #132c8c">
                                        + Add Tags
                                    </router-link>
                                </div>
                                <!--          </div>-->
                            </div>
                        </div>

                        <!-- Duration/value & enddate -->
                        <div v-if="state.type === 'expenses' && state.expenseType === 'prepaid'" class="flex pa3">
                            <div class="pt1 pr2 w-50">
                                <div class="flex flex-column">
                                    <div class="flex">
                                        <label class="pb2">Duration(months)</label>
                                    </div>

                                    <input
                                        @change="(input) => (state.duration = parseFloat(input.target.value))"
                                        class="product-input"
                                        type="number"
                                        step="any"
                                        name="duration"
                                        :placeholder="state.duration"
                                        id="duration"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="pt1 pl2 w-50">
                                <div class="flex flex-column">
                                    <label class="pb2">End Date</label>

                                    <input
                                        class="product-input"
                                        type="date"
                                        name="endDate"
                                        placeholder="End Date"
                                        id="endDate"
                                        required
                                        v-model="state.endDate"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            id="is-credit"
                            v-if="state.type === 'sales' || state.type === 'purchases'"
                            class="flex items-center flex-wrap"
                        >
                            <template v-if="state.entityPaidAdvanceAmount > 0 && state.type === 'sales' && incomingEntityId">
                                <div class="flex pa3 items-center">
                                    <input
                                        type="checkbox"
                                        name="useAdvancePayment"
                                        id="useAdvancePayment"
                                        v-model="state.useAdvancePayment"
                                    />
                                    <label for="useAdvancePayment" class="pl2">Use Advance Payment</label>
                                </div>
                            </template>
                        </div>

                        <!-- creat transaction & cancel Transactions button -->
                        <div v-if="state.type === 'expenses'" class="flex justify-between" style="border-top: 1px solid #e2e8ee">
                            <div class="pa3 flex">
                                <form @submit.prevent="onReceiptSubmit">
                                    <input
                                        type="file"
                                        name="imageFile"
                                        id="file"
                                        class="product-input"
                                        @change="onFileChange"
                                        accept="png jpg jpeg pdf doc docx"
                                    /><label class="proof">Upload receipt/proof of payment</label>
                                </form>
                            </div>
                        </div>

                        <div class="flex justify-between" style="border-top: 1px solid #e2e8ee">
                            <div class="pa3 flex">
                                <button type="reset" class="btn2" :disabled="state.isDisabled">Cancel</button>
                                <button
                                    id="create-transaction"
                                    type="submit"
                                    class="ml2"
                                    :disabled="state.isDisabled"
                                    :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                                >
                                    {{ state.isDisabled ? 'Processing, please wait...' : 'Create Transaction' }}
                                </button>
                            </div>
                        </div>
                        <!-- creat transaction & cancel Transactions button -->
                    </form>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { computed, onBeforeUpdate, reactive, ref, watch, onMounted, inject, watchEffect } from 'vue'
import Swal from 'sweetalert2'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import moment from 'moment-timezone'
import ShortUniqueId from 'short-unique-id'
import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
import 'v-onboarding/dist/style.css'
import AppWrapper from '@/layout/AppWrapper'
import CreateEntity from '@/components/CreateEntity'
import CreateProduct from '@/components/CreateProduct'
import ProductVariantsModal from '@/components/ProductVariantsModal'
import SelectAccount from '@/components/SelectAccount'
import CreateAssetCategory from '@/components/CreateAssetCategory'
import CreateAsset from '@/components/CreateAsset'
import CurrencyInput from '@/components/CurrencyInput'
import { formatAmount, formatQuantity } from '@/utils/lib'
import LowStockNotice from '@/views/Transactions/widgets/LowStockNotice'
import VueTagsInput from '@sipec/vue3-tags-input'
export default {
    name: 'AddTransaction',
    components: {
        AppWrapper,
        CreateEntity,
        SelectAccount,
        CreateAsset,
        CreateAssetCategory,
        CreateProduct,
        CurrencyInput,
        LowStockNotice,
        VOnboardingWrapper,
        ProductVariantsModal,
        VueTagsInput,
    },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true,
            },
        }
    },

    setup() {
        const formRef = ref(null)
        const noProductInInventory = ref(false)
        const retrievedProducts = ref([])
        const retrievedProducts2 = ref([])
        const retrievedProducts3 = ref([])
        const createProductBtn = ref(null)
        const createProductBtn2 = ref(null)
        const createProductBtn3 = ref(null)
        const wrapper = ref(null)
        const { start } = useVOnboarding(wrapper)
        const steps = [
            {
                attachTo: { element: '#sales-transaction' },
                content: {
                    title: 'Record Sales!',
                    description: 'Seclect Money-In to record a sales transaction',
                },
            },
            {
                attachTo: { element: '#select-type' },
                content: {
                    title: 'Select Transaction Type',
                    description: 'Choose the type of transaction you want to record. Default is sales',
                },
            },
            {
                attachTo: { element: '#enter-item' },
                content: {
                    title: 'Select Item',
                    description: 'Enter or select the item sold',
                },
            },
            {
                attachTo: { element: '#more-items' },
                content: {
                    title: 'Add More Items',
                    description: 'Sold more than one item, add more by clicking here',
                },
            },
            {
                attachTo: { element: '#additional-cost' },
                content: {
                    title: 'Additional Cost',
                    description:
                        'You can add additional costs like delivery, commission, VAT, discount, and other fees to your sales',
                },
            },
            {
                attachTo: { element: '#add-customer' },
                content: {
                    title: 'Add Customer',
                    description: 'Select or create a customer',
                },
            },
            {
                attachTo: { element: '#total-amount' },
                content: {
                    title: 'Total Amount',
                    description: 'The total amount of the transaction',
                },
            },
            {
                attachTo: { element: '#add-date' },
                content: {
                    title: 'Enter Date',
                    description: 'Enter the date the transaction happened',
                },
            },
            {
                attachTo: { element: '#is-credit' },
                content: {
                    title: 'Is Credit Transaction?',
                    description: 'If the transaction is on credit, check this box',
                },
            },
            {
                attachTo: { element: '#create-transaction' },
                content: {
                    title: 'Create Transaction',
                    description: 'Click the create transaction button to display a modal finalize',
                },
                on: {
                    afterStep: function () {
                        stopTransactionTour()
                    },
                },
            },
        ]
        const router = useRouter()
        const store = useStore()
        const incomingEntityId = computed(() => store.state.Entity?.incomingEntityId)
        const incomingProductName = computed(() => store?.state.Inventory.incomingProductName)
        const incomingEntityName = computed(() => store.state.Entity?.incomingEntityName)
        const productIds = computed(() => store.state.Inventory?.productIds)
        const productName = computed(() => store.state.Inventory?.productName)
        const productPrices = computed(() => store.state.Inventory?.productPrices)
        const createProductNotifier = computed(() => store.state.Inventory?.createProductNotifier)
        const productEntrySearchList = computed(() => store.state.Inventory?.productEntrySearchList)
        const EntitySearchedList = computed(() => store.state.Entity?.entityListFromSearch)
        const prodsFromTransactionReference = computed(() => store.state.Transaction?.prodsFromTransactionReference)
        const role = computed(() => store?.state?.Auth?.role)
        const orgData = computed(() => store.state?.Settings?.organization)
        const plan = computed(() => store.state?.Settings?.paymentPlan)
        const emailVerifiedAt = computed(() => store?.state?.Auth?.userData?.emailVerifiedAt)
        const assetCategories = computed(() => store.state?.Transaction?.assetsCategories)
        const accountTaxDefault = computed(() => store.state?.Settings?.accountTaxDefault)
        const isTransactionSuccessful = computed(() => store.state.Inventory?.isSuccess)
        const isLoading = computed(() => store.state.Transaction?.isLoading)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const transactionTourStatus = computed(() => store.state?.Settings?.transactionTourStatus)
        const allowZeroStockLevel = computed(() => store.state?.Settings?.allowZeroStockLevel)
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)

        const inflowTypes = [
            'capital',
            'borrowings',
            'advance_payment',
            'service',
            'shipping',
            'vat_received',
            'interest',
            'purchase_return',
            'stock_introduced',
            'discount',
            'debt_repayment',
            'gift_received',
            'assets_sales',
            'others_in_amount',
            'damaged_missing_used_stock',
        ]

        const outflowTypes = [
            'purchases',
            'asset',
            'credit_repayment',
            'loan',
            'cc',
            'withdrawal',
            'sales_return',
            'damaged_stock',
            'missing_stock',
            'promotion_stock',
            'loans_given',
            'bad_debt',
            'vat_remittance',
            'others_in_amount',
        ]

        const startTransactionTour = () => {
            if (transactionTourStatus.value) {
                start()
            }
        }

        const stopTransactionTour = () => {
            store.dispatch('Settings/stopTransactionTour')
        }

        onMounted(() => {
            const params = new URLSearchParams(window.location.search)
            let type = params.get('prepayment')


            if (type) {
                state.transactionType = 'outflows'
                state.type = 'expenses'
                state.subtype = 'expenses'
                state.expenseType = 'prepaid'
            }
            store.dispatch('Settings/getPlan')
            store.dispatch('Settings/getBankAccounts')
            store.dispatch('Settings/getOrganization')
            store.dispatch('Settings/getAccountSettings')
            store.dispatch('Settings/getAccountSettings').then((data) => {
                if (data.status) {
                    state.allowZeroStockLevel = data?.data?.allowZeroStockLevel || false
                }
            })
            store.dispatch('Tag/getTags').then((data) => {
                if (data.status) {
                    state.myTags = data?.data || []
                }
            })
            startTransactionTour()
            //  alert('prepaid')
        })

        onMounted(() => {
            const params2 = new URLSearchParams(window.location.search)
            let direct = params2.get('direct')
            let type = params2.get('type')
            let subtype = params2.get('subtype')

            if (direct) {
                state.transactionType = type || 'outflows'
                state.type = subtype || 'expenses'
                state.subtype = subtype || 'expenses'
                state.expenseType = 'direct'
            }
            // alert('direct')
        })

        onMounted(() => {
            const paramss = new URLSearchParams(window.location.search)
            let type = paramss.get('category')

            if (type) {
                state.transactionType = 'outflows'
                state.type = 'purchases'
            }
        })

        const toggleButtons = (value) => {
            state.type = value
        }

        const businessType = computed(() => store?.state?.DashboardStats?.businessType)
        const initialState = () => ({
            isRelative: false,
            isDisabled: false,
            extras: {
                shipping: false,
                commission: false,
                discount: false,
                vat: false,
                others: false,
                tags: false,
            },
            currProdIndex: 0,
            shipping: 0,
            shippingType: 'fixed',
            commission: 0,
            commissionType: 'percent',
            discount: 0,
            discountType: 'percent',
            vat: 0,
            vatType: 'percent',
            others: 0,
            othersType: 'fixed',
            entityDropDown: false,
            transactionType: 'inflows',
            type: businessType.value,
            subtype: 'sales',
            entity: '',
            entityId: '',
            credit: false,
            useAdvancePayment: false,
            date: moment().format('YYYY-MM-DD'),
            reference: new ShortUniqueId({ length: 6 }).randomUUID(),
            parent_ref: '',
            referenceId: '',
            due_date: '',
            products: [],
            description: '',
            prepayment: false,
            prepayment_months: '',
            prepayment_due_date: '',
            amount: 0,
            totalWithDiscount: 0,
            totalWithAdditionalCost: 0,
            initial_deposit: 0,
            isActive: false,
            entityPaidAdvanceAmount: 0,
            entityAdvanceAmountPlaceholder: 0,
            entityOutstanding: 0,
            entityOutstandingCopy: 0,
            lowStockArray: [],
            lowItems: [],
            orgCategory: '',
            imageFile: null,
            expenseType: 'direct',
            duration: 0,
            endDate: '',
            monthlyRepayment: 0,
            assetCategories: [],
            lifeSpan: 0,
            monthlyDepreciation: 0,
            assetCategory: '',
            assetValue: 0,
            isLoading: false,
            assets: [
                {
                    category: '',
                    group: '',
                    life_span: 0,
                    org: '',
                    _id: '',
                    productDropDown: false,
                },
            ],

            categoryAssets: [
                { name: '', asset: '', price: 0, quantity: 1, instock: 0, productDropDown: false, sku: '', id: '', category: '' },
            ],
            assetsproducts: [],
            showAlertStatus: false,
            tag: '',
            tags: [],
            myTags: [],
            validation: [
                {
                    classes: 'min-length',
                    rule: (tag) => tag.text.length < 3,
                },
                {
                    classes: 'no-numbers',
                    rule: /^([^0-9]*)$/,
                },
                {
                    classes: 'avoid-item',
                    rule: /^(?!Cannot).*$/,
                    disableAdd: true,
                },
                {
                    classes: 'no-braces',
                    rule: ({ text }) => text.indexOf('{') !== -1 || text.indexOf('}') !== -1,
                },
            ],
            account: 'Cash',
        })
        let state = reactive(initialState())

        onBeforeUpdate(() => {
            retrievedProducts.value = []
            retrievedProducts2.value = []
            retrievedProducts3.value = []
            createProductBtn.value = null
            createProductBtn2.value = null
            createProductBtn3.value = null
        })

        watch(
            () => orgData.value,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    if (newVal) {
                        state.orgCategory = newVal.orgCategory
                    }
                }
            }
        )

        const onFileChange = (e) => {
            // false && console.log(e.target.files, 'e.target.files')
            state.imageFile = e.target.files?.[0]
            onReceiptSubmit()
        }
        const onReceiptSubmit = () => {
            state.isDisabled = true
            const formData = new FormData()
            formData.append('imageFile', state.imageFile)
            formData.append('reference', state.reference)
            // console.log('formData', formData.getAll('imageFile'))
            store.dispatch('Transaction/saveExpenseReceipt', formData).then((resp) => {
                if (resp) {
                    // state.imageFile = null
                    state.isDisabled = false
                }
            })
        }
        watch(
            () => state.orgCategory,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    if (newVal) {
                        // console.log('orgCategory', newVal)
                        // store.dispatch('Transaction/getAssetCategories', { orgCategory: newVal })
                    }
                }
            }
        )

        watch(
            () => state.assetCategory,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    if (newVal) {
                        const cat = state.assetCategories.find((x) => x._id === newVal)
                        state.assetValue = cat.value
                    }
                }
            }
        )

        watch(
            () => [state.transactionType],
            (currValue) => {
                if (currValue == 'inflows') {
                    state.type = 'sales'
                    state.subtype = 'sales'
                } else if (currValue == 'outflows') {
                    // state.type = 'expenses'
                    // state.subtype = 'expenses'
                }
            }
        )

        watch(
            () => [state.account],
            (currValue) => {
                if (currValue == 'credit') {
                    state.credit = true
                } else {
                    state.credit = false
                }
            }
        )

        watch(
            () => [state.type, state.subtype],
            (currValue, prevValue) => {
                const { type, subtype } = state

                if (currValue[0] !== prevValue[0]) {
                    if (currValue[0] === 'sales' || currValue[0] === 'purchases' || currValue[0] === 'expenses') {
                        state.type = currValue[0]
                        state.subtype = currValue[0] !== 'expenses' ? currValue[0] : ''
                    } else if (inflowTypes.includes(currValue[0])) {
                        state.type = 'inflows'
                        state.subtype = currValue[0]
                    } else if (outflowTypes.includes(currValue[0])) {
                        state.type = 'outflows'
                        state.subtype = currValue[0]
                    }
                }

                if (
                    currValue[0] === 'purchases' ||
                    // currValue[0] === 'sales' ||
                    currValue[0] === 'expenses' ||
                    currValue[0] === 'inflows' ||
                    currValue[0] === 'outflows' ||
                    currValue[0] === 'assets_purchase'
                ) {
                    state.orgCategory = 'PRODUCT'
                    // console.log('nameee', state.orgCategory)
                    // console.log('TYEPEPEPEEE', state.type)
                }

                // if state type is asset get category
                if (currValue[0] === 'assets_purchase') {
                    //         const query = {
                    //             page: route.query.page || 1,
                    //             limit: route.query.limit || 25,
                    //             sort: 'date'
                    // }
                    // dispatch asset category
                    store.dispatch('Transaction/getAssetCategories')
                }
                if (currValue[1] === 'assets_sales') {
                    store.dispatch('Transaction/getAssetCategories')
                }
            }
        )

        watch(
            () => assetCategories.value,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    if (newVal) {
                        // console.log('assetCategories', newVal?.assets)
                        const assets = newVal?.assets.filter((asset) => asset.category)
                        state.assetCategories = assets
                    }
                }
            }
        )
        watch(
            () => state.subtype,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    if (newVal) {
                        // console.log('subtype', newVal)
                        if (state.type === 'assets_purchase') {
                            const assets = state.assetCategories.find((asset) => asset.category === newVal)
                            // console.log('assets', assets.category, assets.life_span)
                            state.lifeSpan = assets?.life_span
                        }

                        if (newVal === 'sales' || newVal === 'purchases' || newVal === 'expenses') {
                            state.type = newVal
                            state.subtype = newVal !== 'expenses' ? newVal : ''
                        } else if (inflowTypes.includes(newVal)) {
                            state.type = 'inflows'
                            state.subtype = newVal
                        } else if (outflowTypes.includes(newVal)) {
                            state.type = 'outflows'
                            state.subtype = newVal
                        }
                    }
                }
            }
        )

        watch(
            () => businessType.value,
            (currValue, prevValue) => {
                // console.log(businessType.value, 'businessType.value')
                // alert(businessType.value, 'watch value')
                const { type } = state
                if (currValue !== prevValue) {
                    formRef.value.reset()
                    Object.assign(state, initialState())
                    state.type = businessType.value
                }
            }
        )

        watch(
            () => [
                // console.log(state)
                state.entity,
                state.shipping,
                state.commission,
                state.discount,
                state.vat,
                state.others,
                state.shippingType,
                state.commissionType,
                state.discountType,
                state.vatType,
                state.othersType,
                state.extras.shipping,
                state.extras.commission,
                state.extras.discount,
                state.extras.vat,
                state.extras.others,
                state.credit,
                state.initial_deposit,
            ],
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    state.showAlertStatus = true
                }
            }
        )

        let initCat = false
        let alerCount = 1
        let newValue = ''
        const showAlert = (currValue, prevValue) => {
            // console.log('categoryyy', state.orgCategory)
            // console.log('prev', prevValue)
            // Use sweetalert2
            if (state.showAlertStatus) {
                Swal.fire({
                    title: `Changing of business model will delete all data. Are you sure you want to switch?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes, Clear it!',
                    denyButtonText: `No, Cancel!`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        state.showAlertStatus = false
                        //  formRef.value.reset()
                        Object.assign(state, initialState())
                        state.orgCategory = currValue
                        state.type = 'sales'
                        // console.log('crrrrrrr', currValue)
                        store.dispatch('Alert/setAlert', {
                            message: `Successfully switch to ${currValue.toLowerCase()} model`,
                            status: true,
                        })

                        if (state.orgCategory == 'PRODUCT') {
                            state.orgCategory = 'PRODUCT'
                        } else if (state.orgCategory == 'SERVICE') {
                            state.orgCategory = 'SERVICE'
                        } else {
                            state.orgCategory = 'PRODUCT'
                        }

                        alerCount = 0

                        // Swal.fire(`Successfully switch to ${currValue.toLowerCase()} model`, '', 'success')
                    } else if (result.isDenied) {
                        state.orgCategory = prevValue

                        alerCount = 0
                        newValue = currValue
                        // initCat = true;
                        store.dispatch('Alert/setAlert', {
                            message: 'Changes are not saved',
                            status: true,
                        })
                        //  Swal.fire('Changes are not saved', '', 'info')
                    }
                })
            }
        }

        watch(
            () => state.orgCategory,
            (currValue, prevValue) => {
                if (newValue !== prevValue) alerCount = 1
                if (currValue !== prevValue && state.type === 'sales') {
                    // currValue = 'PRODUCT'
                    // console.log('showme', currValue)
                    // console.log('orggggcategoooo', state.orgCategory)
                    // console.log('showmeTTOOOOPREV', prevValue)
                    !initCat || alerCount !== 0 /*&& showAlert(currValue, prevValue)*/
                    initCat = true
                }
            }
        )
        watch(
            () => incomingEntityId.value,
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    state.entityId = incomingEntityId.value
                }
            }
        )
        watch(
            () => incomingEntityName.value,
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    state.entity = incomingEntityName.value
                }
            }
        )
        const totalAmountValue = computed(() => {
            return state.products.reduce((prevVal, currValue) => {
                // eslint-disable-next-line no-param-reassign
                prevVal += currValue.price * currValue.quantity
                return prevVal
            }, 0)
        })
        watch(
            () => [state.discount, state.discountType, state.extras.discount],
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    let discount = 0
                    if (state?.extras?.discount) {
                        if (state.discountType !== 'fixed') {
                            discount = (state.discount / 100) * state.amount
                        } else {
                            discount = state.discount
                        }

                        state.totalWithDiscount = state.amount - discount
                        // console.log("CALCULATED: ", discount, state.totalWithDiscount)
                    }

                }
            }
        )
        watch(
            () => [
                totalAmountValue.value,
                state.totalWithDiscount,
                state.shipping,
                state.commission,
                state.vat,
                state.others,
                state.shippingType,
                state.commissionType,
                state.vatType,
                state.othersType,
                state.extras.shipping,
                state.extras.commission,
                state.extras.vat,
                state.extras.others,
            ],
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    let shipping = 0
                    let commission = 0
                    let vat = 0
                    let others = 0
                    if (state?.extras?.shipping) {
                        if (state.shippingType !== 'fixed') {
                            // eslint-disable-next-line no-param-reassign
                            shipping = (+state.shipping / 100) * +state.totalWithDiscount
                        } else {
                            // eslint-disable-next-line no-param-reassign
                            shipping = +state.shipping
                        }
                    }
                    if (state?.extras?.commission) {
                        if (state.commissionType !== 'fixed') {
                            // eslint-disable-next-line no-param-reassign
                            commission = (+state.commission / 100) * +state.totalWithDiscount
                        } else {
                            // eslint-disable-next-line no-param-reassign
                            commission = +state.commission
                        }
                    }
                    if (state?.extras?.vat) {
                        if (state.vatType !== 'fixed') {
                            // eslint-disable-next-line no-param-reassign
                            vat = (+state.vat / 100) * +state.totalWithDiscount
                        } else {
                            // eslint-disable-next-line no-param-reassign
                            vat = +state.vat
                        }
                    }
                    if (state?.extras?.others) {
                        if (state.othersType !== 'fixed') {
                            // eslint-disable-next-line no-param-reassign
                            others = (+state.others / 100) * +state.totalWithDiscount
                        } else {
                            // eslint-disable-next-line no-param-reassign
                            others = +state.others
                        }
                    }
                    // eslint-disable-next-line no-param-reassign
                    state.totalWithAdditionalCost = +state.totalWithDiscount + +shipping + +commission + +vat + +others
                }
            }
        )
        const handleGenerateProductReturns = () => {
            let type
            if (state.type === 'inflows') {
                if (state.subtype === 'purchase_return') {
                    type = 'purchases'
                }
            }
            if (state.type === 'outflows') {
                if (state.subtype === 'sales_return') {
                    type = 'sales'
                }
            }
            const txData = {
                type,
                reference: state.reference,
            }
            store.dispatch('Transaction/setProductsFromTransactionRef', txData)
        }
        const handleIsRelative = (value = false) => {
            setTimeout(() => {
                state.isRelative = value
            }, 300)
        }
        const showDropDownOnSelectAsset = (index, dropDownState = false) => {
            if (!dropDownState) {
                // return setTimeout(() => {
                setTimeout(() => {
                    // console.log(state.assets, 'state.assets')
                    if (state.assets?.[index]?.productDropDown) {
                        state.assets[index].productDropDown = dropDownState
                    }
                }, 300)
            } else {
                state.assets[index].productDropDown = dropDownState
            }
        }
        const showDropDownOnSelectProduct = (index, dropDownState = false, assetPurchase) => {
            if (!dropDownState && assetPurchase !== 'asset') {
                // return setTimeout(() => {
                setTimeout(() => {
                    if (state.products?.[index]?.productDropDown) {
                        state.products[index].productDropDown = dropDownState
                    }
                }, 300)
            } else if (!dropDownState && assetPurchase === 'asset') {
                setTimeout(() => {
                    if (state.categoryAssets[index]?.productDropDown) {
                        state.categoryAssets[index].productDropDown = dropDownState
                    }
                }, 300)
            } else if (dropDownState && assetPurchase === 'asset') {
                state.categoryAssets[index].productDropDown = dropDownState
            } else {
                state.products[index].productDropDown = dropDownState
            }
        }
        const showDropDownOnSelectEntity = (dropDownState = false) => {
            if (!dropDownState) {
                return setTimeout(() => {
                    state.entityDropDown = dropDownState
                }, 300)
            }
            state.entityDropDown = dropDownState
        }
        const onChangeProductIds = (index, value) => {
            if (state.products[index]) {
                // state.products[index].product = productIds.value[index]
                if (value) {
                    store.dispatch('Inventory/removeProductId', index)
                } else {
                    state.products[index].product = productIds.value[index]
                    // state.products[index].product = productIds.value[index]
                    // console.log(state.products[index].product, productIds.value[index])
                    if (!state.products?.[index]?.price) {
                        // eslint-disable-next-line
                        state.products[index].price =
                            state.type === 'sales'
                                ? productPrices.value[index]?.sellingPrice || productPrices.value[index]?.unitPrice || 0
                                : productPrices.value[index]?.buyingPrice || 0
                    }
                    // eslint-disable-next-line
                    watch(
                        () => state.products?.[index]?.product,
                        // eslint-disable-next-line
                        (prev, next) => {
                            // eslint-disable-next-line
                            if (state.products?.[index]?.price) {
                                // eslint-disable-next-line
                                state.products[index].price =
                                    state.type === 'sales'
                                        ? productPrices.value[index]?.sellingPrice || productPrices.value[index]?.unitPrice || 0
                                        : productPrices.value[index]?.buyingPrice || 0
                            }
                        }
                    )
                }
            }
        }
        const onChangeCurrProdIndex = (newIndex) => {
            state.currProdIndex = newIndex
        }
        // Keep track of newly created product and set it's id automatically
        watch(
            () => productIds.value?.[state.currProdIndex],
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    onChangeProductIds(state.currProdIndex)
                }
            }
        )
        // Keep track of newly created product and name and update when product name is changed
        watch(
            () => productName.value,
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    state.products[state.currProdIndex].name = productName.value
                }
            }
        )
        watch(
            () => incomingProductName.value,
            () => {
                // eslint-disable-next-line no-underscore-dangle
                state.products[state.currProdIndex].name = incomingProductName.value
                // console.log(state.category, 'new')
            }
        )
        const onChangeEntityId = () => {
            state.entityId = incomingEntityId.value
        }
        // const mainInStockValue = tempInStock?.value
        //   ? parseFloat(tempInStock?.value || 0)
        //   : parseFloat(value?.qty_in_stock || 0)
        const tempInStock = computed(() => store?.state?.Inventory?.tempInStock)
        // Just a place holder to hold quatity after creating a product pending when assigned
        let newInStock
        watch(
            () => tempInStock.value,
            (curr, prev) => {
                if (curr !== prev) {
                    newInStock = parseFloat(curr || 0)
                }
            }
        )
        const handleProdClickFromSearch = (value, index, assetPurchase) => {
            if (value.hasVariants) {
                store.dispatch('Inventory/getProductVariants', value._id)
                store.dispatch('Inventory/toggleProductVariantsModal', { status: true, index })
                return
            }
            state.showAlertStatus = true
            const payload = { value, index }
            const purchaseQty = parseFloat(value?.purchase_qty || 0)
            const salesQty = parseFloat(value?.sales_qty || 0)
            const inStock = purchaseQty - salesQty
            const mainInStockValue = parseFloat(value?.qty_in_stock || 0)
            if (assetPurchase === 'asset') {
                state.categoryAssets[index].instock = newInStock || mainInStockValue || inStock
                newInStock = 0
                state.categoryAssets[index].name = value.name
                state.categoryAssets[index].sku = value.sku
                state.categoryAssets[index].id = value._id
                state.categoryAssets[index].price = value.buyingPrice
            }

            state.products[index].instock = mainInStockValue || inStock
            newInStock = 0

            state.products[index].name = value.name
            state.products[index].sku = value.sku
            state.products[index].id = value._id
            state.products[index].measurement = value.measurement
            state.products[index].type = value.type
            onChangeProductIds(index)
            // eslint-disable-next-line
            store.dispatch('Inventory/setProductIdFromExistingProducts', payload).then((resp) => {
                // if (resp) onChangeProductIds(index)
            })
            showDropDownOnSelectProduct(index)
        }
        const handleAssetClickFromSearch = (value, index) => {
            // Temporary in-stock value if a product was created with quantity and an automatic buy transaction completed successfully
            const payload = { value, index }
            // get asset value and lifespan
            state.lifeSpan = value.life_span
            state.assetCategory = value?._id
            state.subtype = value.category
            // const purchaseQty = parseFloat(value?.purchase_qty || 0)
            // const salesQty = parseFloat(value?.sales_qty || 0)
            // const inStock = purchaseQty - salesQty
            // const mainInStockValue = parseFloat(value?.qty_in_stock || 0)
            // state.products[index].instock = newInStock || mainInStockValue || inStock
            // newInStock = 0
            // state.products[index].instock = mainInStockValue || inStock
            state.assets[index] = value
            // state.products[index].sku = value.sku
            // state.products[index].id = value.id
            // store.dispatch('Inventory/setProductIdFromExistingProducts', value._id).then((resp) => {
            onChangeProductIds(index)
            // eslint-disable-next-line
            store.dispatch('Inventory/setProductIdFromExistingProducts', payload).then((resp) => {
                // if (resp) onChangeProductIds(index)
            })
            showDropDownOnSelectAsset(index)
            // if (state.type === 'sales') {
            //     if (state.products[index].quantity < 100) {
            //         store.dispatch('Alert/setAlert', {
            //             message: `The selected product "(${value.name})" is low in stock, you need to purchase more`,
            //             status: false,
            //         })
            //     }
            // }
        }

        const handleLowProductAlert = (index) => {
            if (state.products[index]?.instock) {
                if (state.type === 'sales' && state.orgCategory.toLowerCase() === 'product' && state.products[index].id !== '') {
                    if (state.products[index].quantity > state.products[index].instock) {
                        // state.lowStockArray.push(index)
                        // store.dispatch('Alert/setAlert', {
                        //     message: `The selected product "(${state.products[index].name})" is low in stock, you need to purchase more to continue`,
                        //     status: false
                        // })
                    }
                }
            }
        }
        watch(
            () => createProductNotifier.value,
            (currValue, prevValue) => {
                // console.log(createProductNotifier.value)
                if (currValue !== prevValue) {
                    handleProdClickFromSearch(createProductNotifier.value, state.currProdIndex)
                }
            }
        )
        const handleEntityOutstanding = (entity) => {
            // eslint-disable-next-line
            const payable =
                Number.isNaN(entity?.receivable_balance) || !entity?.receivable_balance
                    ? 0
                    : parseFloat(entity?.receivable_balance)
            // eslint-disable-next-line
            const receivable =
                Number.isNaN(entity?.payable_balance) || !entity?.payable_balance ? 0 : parseFloat(entity?.payable_balance)
            if (state.subtype === 'debt_repayment' || state.subtype === 'bad_debt') {
                state.entityOutstanding = payable
                state.entityOutstandingCopy = payable
            } else {
                state.entityOutstanding = receivable
                state.entityOutstandingCopy = receivable
            }
        }

        watch(
            () => state.amount,
            (prev, next) => {
                if (state.subtype === 'debt_repayment' || state.subtype === 'bad_debt' || state.subtype === 'credit_repayment') {
                    const outstandingVar = parseFloat(state.entityOutstanding)
                    if (prev !== next) {
                        state.entityOutstandingCopy = outstandingVar - parseFloat(state.amount)
                        if (state.amount < 1) {
                            state.amount = 0
                            state.entityOutstandingCopy = outstandingVar
                        }
                        if (state.amount >= outstandingVar) {
                            state.amount = outstandingVar
                            state.entityOutstandingCopy = 0
                        }
                    }
                }
            }
        )
        watch(
            () => state.initial_deposit,
            () => {
                if (state.credit) {
                    if (state.initial_deposit > state.totalWithAdditionalCost) {
                        store.dispatch('Alert/setAlert', {
                            message: 'Upfront payment cannot be greater than the total amount due.',
                            status: false,
                        })
                        state.initial_deposit = state.totalWithAdditionalCost
                    }
                    if (state.initial_deposit < 0) {
                        store.dispatch('Alert/setAlert', {
                            message: 'Upfront payment cannot be less than 0 or negative',
                            status: false,
                        })
                        state.initial_deposit = 0
                    }
                }
            }
        )
        const handleEntityClickFromSearch = (value) => {
            // console.log(value, 'value')
            if (value?.lastName || value?.lastName === undefined) {
                state.entity = `${value?.firstName} ${value?.lastName || ''}` || value?.company
            } else {
                state.entity = `${value?.firstName}` || value?.company
            }
            if (value?.advance_amount) {
                state.entityPaidAdvanceAmount = parseFloat(value?.advance_amount)
            } else {
                state.entityPaidAdvanceAmount = 0
            }
            // eslint-disable-next-line
            state.entityName = value._id
            if (state.subtype === 'debt_repayment' || state.subtype === 'bad_debt' || state.subtype === 'credit_repayment') {
                handleEntityOutstanding(value)
            }
            // eslint-disable-next-line no-underscore-dangle
            store.dispatch('Entity/setEntityIdFromExistingEntities', value._id).then((resp) => {
                if (resp) onChangeEntityId()
            })
            showDropDownOnSelectEntity(false)
        }
        watch(
            () => [state.totalWithAdditionalCost, state.useAdvancePayment],
            () => {
                if (state.useAdvancePayment) {
                    // eslint-disable-next-line
                    state.entityAdvanceAmountPlaceholder =
                        parseFloat(state.totalWithAdditionalCost) - parseFloat(state.entityPaidAdvanceAmount)
                    // console.log(state.totalWithAdditionalCost, state.entityPaidAdvanceAmount)
                    if (state.entityAdvanceAmountPlaceholder < 0) {
                        state.entityAdvanceAmountPlaceholder = 0
                    }
                }
            }
        )
        let timeOut
        const handleProductSearchOnChange = (query, newIndex, assetProduct) => {
            // onChangeProductIds(newIndex, 'reset')
            state.products[newIndex].name = query
            if (assetProduct === 'asset') state.categoryAssets[newIndex || 0].name = query
            const type = state.type === 'sales' ? 'BOTH' : state.orgCategory.toUpperCase()
            clearTimeout(timeOut)
            onChangeCurrProdIndex(newIndex)
            if (query.trim().length && assetProduct !== 'asset') {
                timeOut = setTimeout(() => {
                    store.dispatch('Inventory/getProductEntryBySearch', { query, type, transactionType: state.type })
                }, 100)
            } else {
                setTimeout(() => {
                    store.dispatch('Inventory/getProductEntryBySearch', {
                        query,
                        type: 'asset_product',
                        assetCategory: state.assetCategory,
                        transactionType: state.type,
                    })
                }, 100)
            }
        }
        const handleAssetSearchOnChange = (query, newIndex) => {
            //onChangeProductIds(newIndex, 'reset')
            // state.products[newIndex].name = `${query}  `
            state.assets[newIndex].category = query
            clearTimeout(timeOut)
            //onChangeCurrProdIndex(newIndex)
            if (query.trim().length) {
                timeOut = setTimeout(() => {
                    store.dispatch('Transaction/getAssetCategories')
                    // store.dispatch('Inventory/getProductEntryBySearch', { query, type })
                }, 300)
            }
        }
        const handleEntitySearchOnChange = (query) => {
            state.entity = query
            clearTimeout(timeOut)
            if (query.trim().length) {
                timeOut = setTimeout(() => {
                    store.dispatch('Entity/getEntityEntryBySearch', { query })
                }, 300)
            }
        }
        const closeLowStockModal = () => {
            state.lowItems = []
        }
        watch(
            () => [state.amount, state.duration, state.endDate, state.date],
            (currValue, prevValue) => {
                // console.log(currValue, prevValue, 'CURR VALUE');
                // if (currValue !== prevValue) {
                state.monthlyRepayment = state.amount / state.duration
                // console.log(state.monthlyRepayment, 'MONTHLY REPAYMENT')
                //state.entity = incomingEntityName.value
                // }
            }
        )
        // monthlyDepreciation
        watch(
            () => [state.amount, state.lifeSpan, state.date, state.totalWithAdditionalCost],
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    state.monthlyDepreciation = state.totalWithAdditionalCost / (state.lifeSpan * 12)
                }
            }
        )
        // watch date
        watch(
            () => [state.amount, state.duration, state.endDate, state.date],
            (currValue, prevValue) => {
                // console.log(currValue, prevValue, 'CURR VALUE DATE');
                // if (currValue !== prevValue) {
                const date = new Date(state.date)
                // momment add n months
                const newDate = moment(date).add(state.duration, 'months').format('yyyy-MM-DD')
                //const newDate = new Date(date.setMonth(date.getMonth() + state.duration))
                // console.log(newDate, 'NEW DATE')
                state.endDate = newDate
                // state.due_date = currValue
            }
            // }
        )
        // watch successful transaction
        watch(
            () => isTransactionSuccessful.value,
            (currValue, prevValue) => {
                // console.log(currValue, prevValue, 'CURR VALUE TRANSACTION');
                if (currValue !== prevValue) {
                    if (currValue) {
                        state.isDisabled = false
                        initCat = false
                        // reset the form
                        Object.assign(state, initialState())
                        formRef.value.reset()
                    }
                }
            }
        )
        // watch isLoading
        watch(
            () => isLoading.value,
            (currValue, prevValue) => {
                // console.log(currValue, prevValue, 'CURR VALUE LOADING')
                if (currValue !== prevValue) {
                    if (currValue) {
                        // state.isDisabled = currValue;
                        state.isLoading = currValue
                    }
                }
            }
        )

        // watch resetProductIds
        watch(
            () => productIds.value,
            (currValue, prevValue) => {
                // false && console.log(currValue, prevValue, 'CURR VALUE RESET PRODUCT IDS')
                if (currValue !== prevValue) {
                    if (currValue.length === 0) {
                        // state.products = []
                        // state.categoryAssets = []
                        // state.assets = []
                        // state.entity = ''
                        formRef.value.reset()
                    }
                }
            }
        )
        const processSubmit = async () => {
            // state.lowItems = []
            closeLowStockModal()
            state.isDisabled = true
            const formData = {}
            formData.date = state.date
            formData.entity = state?.entityId
            formData.reference = state.reference
            formData.amount = state.amount
            formData.type = state.type
            formData.description = state.description
            formData.orgCategory = 'PRODUCT' // state.orgCategory
            formData.entityName = state.entity
            formData.tags = state.tags.map((tag) => {
                return tag.text
            })
            const extrasArr = ['shipping', 'commission', 'discount', 'vat', 'others']
            delete state.entityOutstanding
            delete state.entityOutstandingCopy
            delete state.lowItems
            delete state.lowStockArray

            // console.log(formData, 'formdataa')

            switch (state.type) {
                case 'sales':
                    formData.amount = state.products.length > 0 ? parseFloat(totalAmountValue.value) : state.amount
                    if (state.useAdvancePayment) {
                        formData.useAdvancePayment = state?.useAdvancePayment || true
                        formData.entityPaidAdvanceAmount = parseFloat(state?.entityPaidAdvanceAmount || 0)
                    }
                // eslint-disable-next-line no-fallthrough
                case 'purchases':
                    formData.options = []
                    // formData.type = state.type
                    formData.products = state.products
                    formData.amount = state.products.length > 0 ? parseFloat(totalAmountValue.value) : state.amount
                    if (!formData.entity && incomingEntityId.value) {
                        formData.entity = incomingEntityId.value || null
                    }
                    if (state.credit) {
                        formData.credit = state.credit
                        formData.due_date = new Date(state.due_date)
                        formData.initial_deposit = parseFloat(state.initial_deposit)
                    }
                    extrasArr.forEach((keyName) => {
                        if (state?.extras?.[keyName] && state?.[keyName]) {
                            const type = `${keyName}Type`
                            formData.options.push({
                                amount: parseFloat(state?.[keyName]),
                                type: state?.[type],
                                label: keyName,
                            })
                        }
                    })
                    if (!formData.options.length) delete formData.options
                    if (
                        state.subtype === 'damaged_stock' ||
                        state.subtype === 'missing_stock' ||
                        state.subtype === 'promotion_stock' ||
                        state.subtype === 'stock_introduced' ||
                        state.subtype === 'bad_debt'
                    ) {
                        store.dispatch('Transaction/createNewTransaction', formData).then((resp) => {
                            if (resp?.success) {
                                Object.assign(state, initialState)
                                state.products = []
                                store.dispatch('Inventory/resetProductIds')
                                state.isDisabled = false
                                state.isLoading = false
                                state.showAlertStatus = false
                                router.push({
                                    name: 'Transaction',
                                    params: { id: formData.reference },
                                })
                            } else {
                                store.dispatch('Inventory/resetProductIds')
                                state.isDisabled = false
                                state.isLoading = false
                            }
                        })
                    } else {
                        state.isDisabled = false
                        // displayAccountModal(true, formData)
                        submitTransaction(formData)
                    }

                    break
                default:
                    if (state.subtype === 'debt_repayment' || state.subtype === 'bad_debt') {
                        formData.subtype = state.subtype === 'debt_repayment' ? 'debt_repayment' : 'bad_debt'
                        formData.amount = state.amount
                        formData.paid = state.amount
                        formData.parent_ref = state.parent_ref
                    } else if (state.subtype === 'credit_repayment') {
                        formData.subtype = 'credit_repayment'
                        formData.amount = state.amount
                        formData.paid = state.amount
                        formData.parent_ref = state.parent_ref
                    } else {
                        formData.subtype = state.subtype
                        if (state.subtype === 'borrowings') {
                            if (state.due_date) {
                                formData.due_date = new Date(state.due_date)
                            }
                        }
                        if (state.description) formData.description = state.description
                        if (state.type === 'expenses' && state.expenseType === 'prepaid') {
                            formData.type = 'prepaid_expense'
                            formData.duration = state.duration
                            formData.expense_type = state.expenseType
                            formData.monthly_repayment = state.monthlyRepayment
                            formData.repayment_due_date = state.endDate
                        }
                        if (state.type === 'expenses' && state.expenseType === 'direct') {
                            formData.expense_type = state.expenseType
                        }
                        formData.entity = state.entityId

                        formData.amount = parseFloat(state.amount || totalAmountValue.value)
                        if (state.subtype === 'sales_return' || state.subtype === 'purchase_return') {
                            formData.products = state.products.reduce((acc, prod) => {
                                const prodObj = {}
                                prodObj.product = prod.product
                                prodObj.description = prod.name
                                prodObj.quantity = parseFloat(prod.quantity)
                                acc.push(prodObj)
                                return acc
                            }, [])
                        }

                        if (
                            state.subtype === 'damaged_stock' ||
                            state.subtype === 'missing_stock' ||
                            state.subtype === 'promotion_stock'
                        ) {
                            formData.items = state.products.reduce((acc, prod) => {
                                const prodObj = {}
                                prodObj.product = prod.product
                                prodObj.productName = prod.name
                                prodObj.quantity = parseFloat(prod.quantity)
                                prodObj.sku = prod.sku
                                prodObj.amount = parseFloat(prod.price)
                                prodObj.id = prod.id
                                acc.push(prodObj)
                                return acc
                            }, [])
                        }
                        // if type is assets_purchase
                        if (state.type === 'assets_purchase') {
                            formData.monthlyDepreciation = state.monthlyDepreciation
                            formData.lifeSpan = state.lifeSpan
                            // compute expiry date
                            const expiryDate = new Date(state.date)
                            expiryDate.setFullYear(expiryDate.getFullYear() + state.lifeSpan)
                            formData.expiryDate = new Date(expiryDate)
                            // console.log(state.products, 'assets purchase')
                            formData.products = state.products
                            formData.amount = parseFloat(totalAmountValue.value)
                            if (!formData.entity && incomingEntityId.value) {
                                formData.entity = incomingEntityId.value || null
                            }
                            if (state.credit) {
                                formData.credit = state.credit
                                formData.due_date = new Date(state.due_date)
                                formData.initial_deposit = parseFloat(state.initial_deposit)
                            }
                        }
                    }
                    // console.log(formData, 'state')
                    if (
                        state.subtype === 'damaged_stock' ||
                        state.subtype === 'missing_stock' ||
                        state.subtype === 'promotion_stock' ||
                        state.subtype === 'stock_introduced' ||
                        state.subtype === 'bad_debt'
                    ) {
                        store.dispatch('Transaction/createNewTransaction', formData).then((resp) => {
                            if (resp?.success) {
                                Object.assign(state, initialState)
                                state.products = []
                                store.dispatch('Inventory/resetProductIds')
                                initCat = false
                                state.isDisabled = false
                                router.push({
                                    name: 'Transaction',
                                    params: { id: formData.reference },
                                })
                            } else {
                                store.dispatch('Inventory/resetProductIds')
                                state.isDisabled = false
                                state.isLoading = false
                            }
                        })
                    } else {
                        state.isDisabled = false
                        // displayAccountModal(true, formData)
                        submitTransaction(formData)
                    }
            }
        }

        watchEffect(async () => {
            const doesNotExistProducts = state.products.filter((item) => item.id === '')

            if (doesNotExistProducts.length >= 1) {
                noProductInInventory.value = true
            } else {
                noProductInInventory.value = false
            }
        })

        const validateItems = () => {
            let isAnyProductInvetoryZero = false
            for (const product of state.products) {
                if (product.type === 'service') {
                    continue
                }
                if (product.quantity > product.instock) {
                    isAnyProductInvetoryZero = true
                    break
                }
            }

            return isAnyProductInvetoryZero
        }

        const onSubmit = async () => {
            // const doesNotExistProducts = state.products.filter(item => !item.sku)
            const isAnyProductInvetoryZero = validateItems()
            if (state.type === 'sales' && allowZeroStockLevel.value && isAnyProductInvetoryZero) {
                store.dispatch('Alert/setAlert', {
                    message: 'Item is not sufficient in inventory, please contact Admin or Inventory Manager to update stock',
                    success: false,
                })
                return false
            }

            if (state.subtype === 'borrowings') {
                if (!state.due_date) {
                    return store.dispatch('Alert/setAlert', {
                        message: 'Please select a valid due date',
                        success: false,
                    })
                }
                if (new Date() >= new Date(state.due_date)) {
                    return store.dispatch('Alert/setAlert', {
                        message: 'Due date cannot be in the past, must be a future date',
                        success: false,
                    })
                }
            }

            if (state.type === 'sales') {
                // if (noProductInInventory.value) {
                //     await store.dispatch('Alert/setAlert', {
                //         message: `One or more items do not exist in your inventory`,
                //         success: false
                //     })
                // }

                if (
                    state.products?.length &&
                    state.orgCategory.toLowerCase() === 'product' &&
                    noProductInInventory.value === false
                ) {
                    const lowItems = []
                    state.products.forEach((item) => {
                        if (item.quantity > item.instock) {
                            lowItems.push(item.name)
                        }
                    })
                    state.lowItems = lowItems

                    // if (state.lowItems.length) return
                }
            }

            processSubmit()
        }
        const toggleExtras = (value = false, stateKey) => {
            state.extras[stateKey] = value
            if (stateKey === 'vat') {
                state.vat = value ? accountTaxDefault.value : 0
            }
        }
        const toggleCreateEntity = (payload) => {
            store.dispatch('Entity/toggleCreateEntity', payload)
            if (payload && state.entity) store.dispatch('Entity/setEntityName', state.entity)
        }
        const toggleCreateAssetCategory = (payload) => {
            store.dispatch('Entity/toggleCreateAssetCAtegory', payload)
        }
        const displayAccountModal = (payload, formData) => {
            store.dispatch('Transaction/showAccountModal', payload)
            if (formData) store.dispatch('Transaction/setFormInfomation', formData)
        }

        const submitTransaction = (data) => {
            const discountedAmount =
                data.amount +
                data?.options?.reduce((acc, item) => {
                    if (item.label == 'discount') {
                        if (item.type === 'percent') {
                            return acc - data.amount * (item.amount / 100)
                        } else {
                            return acc - item.amount
                        }
                    }

                    return acc
                }, 0)

            const calculateExtras =
                discountedAmount +
                data?.options?.reduce((acc, item) => {
                    if (item.label !== 'discount') {
                        if (item.type === 'percent') {
                            return acc + discountedAmount * (item.amount / 100)
                        } else {
                            return acc + item.amount
                        }
                    }
                    return acc
                }, 0)

            if (data?.credit) {
                data.subtotal = data.amount
            } else {
                // data.subtotal = !calculateExtras ? data.amount : calculateExtras
                data.subtotal = data.amount
                data.glAccounts = [{
                    glAccount: state.account,
                    glAmount: !calculateExtras ? data.amount : calculateExtras
                }]
            }

            if (data?.products?.length === 0) {
                delete data.products
            }

            if (data.tags.length == 0) {
                delete data.tags
            }

            // console.log('DATA: ', data)
            store.dispatch('Transaction/setIsLoading', true)
            state.isDisabled = true
            store.dispatch('Transaction/createNewTransaction', data).then((resp) => {
                if (resp?.success) {
                    Object.assign(state, initialState)
                    state = reactive(initialState)
                    state.products = []
                    state.amount = 0
                    store.dispatch('Inventory/resetProductIds')
                    state.isDisabled = false
                    router.push({ name: 'Transaction', params: { id: data?.reference } })
                } else {
                    store.dispatch('Inventory/resetProductIds')
                    state.isDisabled = false
                }
            })
        }

        const handleSearchOrCreateProduct = async (n) => {
            setTimeout(() => {
                if (store.state.Inventory?.productEntrySearchList?.length) {
                    if (n === 1) {
                        retrievedProducts.value[0].click()
                        state.showAlertStatus = true
                    } else if (n === 2) {
                        retrievedProducts2.value[0].click()
                        state.showAlertStatus = true
                    } else if (n === 3) {
                        retrievedProducts3.value[0].click()
                        state.showAlertStatus = true
                        // console.log('ENTER triggered')
                    }
                } else {
                    if (n === 1) {
                        createProductBtn.value.click()
                    } else if (n === 2) {
                        createProductBtn2.value.click()
                    } else if (n === 3) {
                        createProductBtn3.value.click()
                    }
                }
                store.dispatch('Inventory/resetProductEntryBySearch', [])
            }, 100)
        }

        const toggleCreateProduct = (payload, index, data) => {
            state.showAlertStatus = true
            store.dispatch('Inventory/showCreateProduct', { show: payload, cat: state.orgCategory })
            if (payload && data) {
                store.dispatch('Inventory/showIncomingProductName', data)
            }
            store.dispatch('Inventory/resetProductEntryBySearch', [])
        }

        // showCreateAssetProduct
        const toggleCreateAssetProduct = (payload, index, data) => {
            store.dispatch('Inventory/showCreateAssetProduct', { show: payload, cat: 'Asset' })
            if (payload && data) {
                store.dispatch('Inventory/showIncomingProductName', data)
            }
        }
        const addMoreProducts = () => {
            if (state.products.length == 0) {
                state.amount = 0
                state.totalWithAdditionalCost = 0
            }
            state.products.push({
                name: '',
                product: '',
                price: 0,
                quantity: 1,
                instock: 0,
                productDropDown: false,
                sku: '',
                id: '',
            })
        }
        const addMoreAssets = () => {
            state.categoryAssets.push({
                name: '',
                asset: '',
                price: 0,
                quantity: 1,
                instock: 0,
                productDropDown: false,
                category: '',
            })
        }
        const deleteProduct = (index) => {
            const { products } = state
            const productToDelete = products.splice(index, 1)
            state.products = products.filter((element) => element !== productToDelete[0])
            store.dispatch('Inventory/removeProductId', index)
        }

        const expenses = [
            { value: 'transport', name: 'Transport Logistics & Delivery' },
            { value: 'commission', name: 'Commission' },
            // { value: 'input_raw_materials', name: 'Raw Materials' },
            // { value: 'ads', name: 'Advertisement' },
            { value: 'packaging', name: 'Packaging & Branding' },
            // { value: 'discount', name: 'Discount Given' },
            // { value: 'fees', name: 'Fees' },
            { value: 'rent', name: 'Rent' },
            { value: 'utility', name: 'Utility' },
            { value: 'interest_expense', name: 'Interest Expense' },
            { value: 'others', name: 'Other Expenses' },
            { value: 'home_office_cost', name: 'Stationary' },
            { value: 'furniture_equipment_machinery', name: 'Furniture/Equipment/Machinery' },
            { value: 'office_supplies', name: 'Office Supplies/Consumables' },
            { value: 'advertising_marketing', name: 'Advertising & Marketing' },
            { value: 'website_software', name: 'IT Expenses' },
            { value: 'entertainment', name: 'Entertainment' },
            { value: 'business_meals_travel_expense', name: 'Business Meals & Travel Expense' },
            { value: 'salary', name: 'Salary/Payroll' },
            { value: 'vehicle_expenses', name: 'Vehicle Cost/Fuel' },
            { value: 'taxes', name: 'Taxes' },
            { value: 'business_insurance', name: 'Business Insurance' },
            { value: 'license_registration', name: 'License & Registration' },
            { value: 'professional_business_fees', name: 'Professional & Business Fees' },
            { value: 'trainings', name: 'Trainings' },
            { value: 'input_raw_materials', name: 'General Accessories/Raw materials' },
            { value: 'bank', name: 'Bank Fees/Charges' },
            { value: 'electricity', name: 'Electricity' },
            { value: 'telephone', name: 'Communication/Airtime' },
            { value: 'csr', name: 'Gift and Donations' },
            { value: 'maintenance_repairs', name: 'Maintenance/Repairs' },
            { value: 'cost_of_goods_sold', name: 'Cost of Service Rendered' },
            { value: 'employee_benefits', name: 'Employee benefits' },
            { value: 'generator', name: 'Generator - Diesel/Fuel' },
            { value: 'internet', name: 'Internet' },
            { value: 'periodicals', name: 'Periodicals (Newspaper, Journals)' },
            { value: 'printing', name: 'Printing' },
            { value: 'utility_cable_tv', name: 'Utility - Cable TV' },
            { value: 'utility_water_and_disposal', name: 'Utility - Waste & Disposal' },
            { value: 'utility_others', name: 'Utility - Others' },
            { value: 'direct_labour', name: 'Direct Labour (Cost of Sales)' },
            { value: 'direct_utility', name: 'Direct Utility (Cost of Sales)' },
        ]

        const inflows = [
            // { value: 'sales', name: 'Sales', permission: 23 },
            { value: 'capital', name: 'Capital', permission: 27 },
            { value: 'borrowings', name: 'Borrowings', permission: 27 },
            // { value: 'advance_payment', name: 'Customer Advance/Deposit', permission: 27 },
            { value: 'service', name: 'Service Charge', permission: 27 },
            { value: 'shipping', name: 'Shipping Fee', permission: 27 },
            { value: 'vat_received', name: 'VAT Received', permission: 27 },
            { value: 'interest', name: 'Interest Earning', permission: 27 },
            // { value: 'purchase_return', name: 'Purchase Return', permission: 27 },
            // { value: 'stock_introduced', name: 'Stock Introduced', permission: 27 },
            // { value: 'discount', name: 'Discount Received', permission: 27 },
            { value: 'debt_repayment', name: 'Debt Repayment', permission: 27 },
            { value: 'gift_received', name: 'Gift Received', permission: 27 },
            // { value: 'assets_sales', name: 'Asset Sales', permission: 27 },
            { value: 'others_in_amount', name: 'Other Income', permission: 27 },
            // { value: 'damaged_missing_used_stock', name: 'Damaged/Missing/Stock used in promotion', permission: 27}
        ]
        const outflows = [
            { value: 'expenses', name: 'Expenses', permission: 24 },
            { value: 'purchases', name: 'Purchases', permission: 25 },
            // { value: 'asset', name: 'Asset Purchase', permission: 26 },
            { value: 'credit_repayment', name: 'Credit Repayment', permission: 26 },
            // { value: 'loan', name: 'Loan Repayment', permission: 26 },
            // { value: 'cc', name: 'Credit Card Payment', permission: 26 },
            { value: 'withdrawal', name: 'Withdrawal', permission: 26 },
            // { value: 'sales_return', name: 'Sales Return', permission: 26 },
            // { value: 'damaged_stock', name: 'Damaged Stock', permission: 26 },
            // { value: 'missing_stock', name: 'Missing Stock', permission: 26 },
            // { value: 'promotion_stock', name: 'Stock used in promotion', permission: 26 },
            { value: 'loans_given', name: 'Loans Given out', permission: 26 },
            { value: 'bad_debt', name: 'Bad Debt', permission: 26 },
            { value: 'vat_remittance', name: 'VAT Remittance', permission: 26 },
            // { value: 'others_in_amount', name: 'Others', permission: 26 }
        ]

        const toggleProductVariantsModal = (payload) => {
            store.dispatch('Inventory/toggleProductVariantsModal', payload)
        }

        const changeBusinessType = (type) => {
            store.dispatch('DashboardStats/getBusinessType', type)
        }

        return {
            state,
            processSubmit,
            deleteProduct,
            addMoreProducts,
            addMoreAssets,
            onSubmit,
            formRef,
            role,
            totalAmountValue,
            expenses,
            inflows,
            outflows,
            toggleExtras,
            toggleCreateEntity,
            toggleCreateAssetCategory,
            onChangeProductIds,
            productIds,
            toggleCreateProduct,
            toggleCreateAssetProduct,
            handleProductSearchOnChange,
            handleEntitySearchOnChange,
            productEntrySearchList,
            EntitySearchedList,
            handleProdClickFromSearch,
            handleEntityClickFromSearch,
            showDropDownOnSelectProduct,
            showDropDownOnSelectEntity,
            onChangeCurrProdIndex,
            handleIsRelative,
            formatAmount,
            formatQuantity,
            handleLowProductAlert,
            closeLowStockModal,
            handleGenerateProductReturns,
            prodsFromTransactionReference,
            incomingEntityId,
            onFileChange,
            onReceiptSubmit,
            handleAssetSearchOnChange,
            showDropDownOnSelectAsset,
            handleAssetClickFromSearch,
            retrievedProducts,
            retrievedProducts2,
            retrievedProducts3,
            createProductBtn,
            createProductBtn2,
            createProductBtn3,
            handleSearchOrCreateProduct,
            emailVerifiedAt,
            rolePermissions,
            accountTaxDefault,
            noProductInInventory,
            toggleButtons,
            wrapper,
            steps,
            toggleProductVariantsModal,
            bankAccounts,
            plan,
            changeBusinessType,
        }
    },
}
</script>
<style>
textarea input modal {
    z-index: 0 !important;
}
.dropdown-content {
    z-index: 99999999 !important;
}
.topHomeBtn {
    background: #dcdcdc;
    padding: 5px;
    border-radius: 9999px;
    /* overflow-x: auto; */
    /* width: 32.8rem; */
}

.topbtns {
    color: black;
    background: white;
    /* padding: 3px; */
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 9999px;
    border: 1px solid #2955f2;
    /* margin-right: 10px; */
}

.topbtns:hover {
    background: #2955f2;
    border: 1px solid #2955f2;
    color: white;
}

.makeActive {
    background: #2955f2 !important;
    color: white !important;
}

.radiobutton {
    background: white;
    border: 1px solid #2955f2;
    padding: 5px;
    border-radius: 999px;
}

.radiobutton:hover {
    background: #2955f2;
    color: white;
}
.aside-icon {
    width: 25px;
    padding-right: 10px;
}

.makehidden {
    display: none;
}
/* @media screen and (min-width: 280px) and (max-width: 350px) {
    .topbtns {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .makeRight {
        margin-left: 10rem;
    }
} */

@media screen and (max-width: 767px) {
    .topbtns {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 8px;
        font-size: 7px;
    }

    .makeRight {
        margin-left: rem;
    }

    .topHomeBtn {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        background: none;
    }
}

.makeItalicExp {
    font-style: italic;
    font-weight: 100;
    margin-top: 6px;
}

.aside-flex {
    display: flex;
}

.container {
    width: 90vw;
}

aside {
    width: 20vw;
    background-color: #f2f6fc;
}

/**************************  Aside styling   ***********************/
.transactions-sidebar-btn {
    background-color: #e9edfc;
    padding: 15px;
}
.transactions-sidebar-btn button {
    color: #ffffff;
    background-color: #132c8c;
    border: none;
    width: 100%;
    border-radius: 2px;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: space-evenly;
    border-radius: 4px;
}

.aside-menu-list li {
    list-style-type: none;
    line-height: 35px;
    cursor: pointer;
}
.transactions-sidebar-profile {
    margin: 20px;
    background-color: #e9edfc;
    display: flex;
    justify-content: space-evenly;
}

.main-aside {
    width: 24%;
    padding: 10px 20px;
}
.main-content {
    width: 70%;
    margin-top: 30px;
    border: 1px solid #e3e8ee;
    margin-bottom: 50px;
    box-sizing: border-box !important;
}

.main-aside li {
    list-style-type: none;
    line-height: 35px;
}

.main-aside p {
    font-weight: bold;
}

.card-header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #e3e8ee;
}
.card-header-add {
    color: #132c8c;
    font-weight: bold;
}
.card-header-ref {
    color: #1a2035;
    font-weight: 600;
}
.form-row {
    display: flex;
    margin-top: 20px;
}
.main-content-form-container {
    background-color: #fbfcfe;
    padding: 20px;
    box-sizing: border-box;
}
.main-form-group-container {
    margin-top: 30px;
}
.form-group {
    width: 100%;
}

.imgArrow {
    margin-left: 4px;
    height: 8px;
}

.makeLandRPadding {
    padding-left: 1rem;
    padding-right: 1rem;
}

.line {
    border-bottom: 1px solid #e3e8ee;
    width: 100%;
    margin: 4px;
}

.main-bar {
    display: flex;
}

.transactionbox {
    width: 55rem;
}

.mb-active {
    background-color: #aab9f3 !important;
    /* padding: 0px 15px; */
}

.toptransactdisp {
    display: flex;
    /* justify-content: center; */
}

.makeAstRed {
    color: red;
}

.makeWhiteTransaction {
    background: white;
}

.top-icon-wrapper {
    background: #ebedeb;
    height: 33px;
    width: 35px;
    border-radius: 999px;
    padding-top: 7px;
    text-align: center;
}

.showOnSmallScreen {
    display: none;
}

@media screen and (max-width: 630px) {
    .main-aside-menu {
        flex-wrap: wrap;
        padding: 0 !important;
    }

    .main-aside-menu li {
        width: 30%;
    }

    .main-bar {
        justify-content: center;
    }

    /* .transactionbox {
        width: 100%;
    } */
}
@media screen and (max-width: 1080px) {
    .main-aside-menu {
        /* text-align: center;
        display: flex;
        padding: 0 !important;
        justify-content: space-between; */
        display: none;
    }

    .main-bar {
        display: flex;
        flex-direction: column;
    }

    .main-aside {
        width: 92%;
        margin: 50px auto 0;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .takeSpaceoff {
        margin-top: 10px !important;
    }

    .main-aside-menu li {
        cursor: pointer;
        box-sizing: border-box !important;
    }

    .mb-hide {
        display: none;
    }

    .showOnSmallScreen {
        display: flex;
    }

    .transactionbox {
        width: 90%;
    }

    .justify-responsive {
        display: flex;
        justify-content: center;
    }

    .toptransactdisp {
        /* display: flex; */
        justify-content: center;
    }

    .makeHiddenOthers {
        display: none;
    }
}

@media screen and (min-width: 601px) and (max-width: 767px) {
    .topbtns {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .makeRight {
        margin-left: 0rem;
    }
}

@media screen and (max-width: 300px) {
    .showOnSmallScreen {
        justify-content: space-around;
    }
}

.product-input {
    border-radius: 3px;
}
.addMeasurement {
    position: relative;
}
.measureUnit {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
.vue-tags-input {
    max-width: 100% !important;
}
</style>
